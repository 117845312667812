import {useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setDomainConfig } from "@onlinesales-ai/app-v2/domainConfig";
import { AppLoader } from "@onlinesales-ai/app-v2";

import { getDomainConfiguration } from "./configuration";

const InitDomainConfig = ({ children, setDomainConfig }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchConfig = async () => {
    try {
      const config = await getDomainConfiguration({});
      setDomainConfig(config);
    } catch (err) {}

    setIsLoaded(true);
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  if (isLoaded) {
    return children;
  }

  return AppLoader();
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setDomainConfig,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InitDomainConfig);
