import React from "react";
import { connect } from "react-redux";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import "./index.less";

const ImageWrapper = ({ imageUrl }) => {
  return (
    <div className="route-image-wrapper">
      <AsyncImage imgSrc={imageUrl} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { pathname = "" } = state.router.location || {};
  const domainConfig = state.DomainConfig || {};

  return {
    imageUrl: domainConfig[pathname] && domainConfig[pathname].imageUrl,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(ImageWrapper);
