import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";

import { ConfirmationModal } from "@onlinesales-ai/modal-window-v2";

import { routes } from "src/utilities/constants";
import { redirectUrl } from "src/store/application";

const AuthModal = ({
  redirectUrlTo,
  fetchUserList,
  hasAdminAccess,
  location,
}) => {
  const { t } = useTranslation();

  if (!fetchUserList && !hasAdminAccess && location.pathname !== routes.LOGIN.path) {
    return (
      <ConfirmationModal
        isShow
        rightBtnText={false}
        title={t("Please login with appropriate credential")}
        actionBtnText={t("Okay")}
        actionBtnCallback={() => redirectUrlTo(routes.LOGIN.path)}
      />
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  return {
    fetchUserList: state.Application.fetchUserList,
    hasAdminAccess: state.Application.hasAdminAccess,
    location: state.router.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrlTo: redirectUrl,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);
