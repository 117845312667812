import React, { Suspense, useContext, useEffect, useRef, useState } from "react";

import { loaderApp } from "@onlinesales-ai/loader-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";

import Drawer from "./Drawer";

const ContextBasedDrawer = ({
  containerClass,
  drawerPropsByComponent,
  suspenseLoading,
  DrawerChildren,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const drawerRef = useRef({});
  const { contextDrawerData, setContextDrawerData } = useContext(GlobalContext);

  const onClickClose = (forceClose = false) => {
    const isDrawerClosable = drawerRef.current?.isDrawerClosable;

    if (!forceClose && typeof isDrawerClosable === "function" && !isDrawerClosable(onClickClose)) {
      return;
    }

    setContextDrawerData({});
    setIsOpen(false);
  };

  useEffect(() => {
    if (contextDrawerData?.component) {
      setIsOpen(true);
    }
  }, [contextDrawerData]);

  return (
    <Drawer
      isOpen={isOpen}
      containerClass={`route-based-drawer-wrapper ${containerClass}`}
      onClickClose={onClickClose}
      {...(drawerPropsByComponent?.[contextDrawerData?.component] || {})}
    >
      <Suspense fallback={suspenseLoading || loaderApp()}>
        <DrawerChildren
          {...(contextDrawerData?.data || {})}
          component={contextDrawerData?.component}
          jobDoneCallback={onClickClose}
          drawerRef={drawerRef}
        />
      </Suspense>
    </Drawer>
  );
};

ContextBasedDrawer.defaultProps = {
  drawerProps: {},
  containerClass: "",
};

export default ContextBasedDrawer;
