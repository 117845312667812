const header = {
  shouldBeRendered: true,
  brandingLogo: "https://res.cloudinary.com/onlinesales/image/upload/w_155,h_25/logos/72htWhiteLogo.png",
  navLinks: {
    common: [
      {
        name: "Dashboard",
        href: "/dashboard",
        className: "dashBoardTitle",
        icon: "icon-dashboard-nav",
        shouldBeRenderOnDesktop: true,
        shouldBeRenderOnMobile: true,
      },
      {
        name: "Setting",
        href: "/settings",
        className: "settingsTitle",
        icon: "icon-settings-nav",
        dropdownOptions: [
          {
            name: "Logout",
            type: "LOGOUT",
            shouldBeRenderOnDesktop: true,
            shouldBeRenderOnMobile: true,
          },
        ],
        shouldBeRenderOnDesktop: true,
        shouldBeRenderOnMobile: true,
      }
    ],
  },
};

const dashboardConfig = {
  props: {},
  componentConfig: {
    funnelSummaryCombo: {
      shouldBeRendered: false,
      shouldBeRenderOnMobile: false,
      shouldBeRenderedForAllClients: false,
      props: {},
    },
    funnel: {
      shouldBeRendered: false,
      shouldBeRenderOnMobile: false,
      shouldBeRenderedForAllClients: false,
      props: {},
    },
    summaryChart: {
      shouldBeRendered: false,
      shouldBeRenderOnMobile: false,
      shouldBeRenderedForAllClients: false,
      props: {
        metricsConfig: {},
        isShowChangeHistory: false,
      },
    },
    clientPerformance: {
      shouldBeRendered: false,
      shouldBeRenderOnMobile: false,
      shouldBeRenderedForAllClients: false,
      props: {},
    },
  },
};

export default {
  header,
  defaultRoute: "/sellerMIS",
  pageTitle: "OnlineSales.ai",
  outletText: "Outlet",
  agencyId: 0,
  logoConfig: {
    loaderWhite: "https://osads.gumlet.io/image/upload/logos/os_loader_white.gif",
    loaderBlack: "https://osads.gumlet.io/image/upload/logos/os_loader_black.gif",
    callbackPageLoader:
      "https://res.cloudinary.com/onlinesales/image/upload/w_60,f_auto,q_auto/logos/callback_page_loader.gif",
    logo_width_52:
      "https://osads.gumlet.io/image/authenticated/s--XHZGSuNX--/v1702397812/christmas/OS_logo_symbol_32X40.svg",
    logo_width_250:
      "https://osads.gumlet.io/image/upload/logos/logo_without_text_250x250.png",
    logo_width_406: "https://osads.gumlet.io/image/upload/logos/logo_406x105.png",
    logo_width_146: "https://res.cloudinary.com/onlinesales/image/upload/w_155,h_25/logos/72htWhiteLogo.png",

    default:
      "https://osads.gumlet.io/image/authenticated/s--XHZGSuNX--/v1702397812/christmas/OS_logo_symbol_32X40.svg",
  },
  "/settings": {
    props: {
      cards: {
        SETTINGS_OS_AI_SETUP_CONFIGURATION: {
          // these should be false by default and made true in individual configs
          shouldBeRendered: true,
          shouldBeRenderOnMobile: true,
        },
      },
    },
  },
  "/dashboard": dashboardConfig,
  commonConfigs: {
    creativeConfig: {
      isShowCreativeApprovalStatus: true,
    },
  },
  agencySettings: {
    default: {
      doNotSendError: true,
      internalUserDomains: ["@onlinesales.ai"],
      // attributionWindow: "30_DAY",
      // uploadTypeByMediaType: {
      //   IMAGE: "GUMLET_CLOUDINARY",
      //   VIDEO: "S3",
      //   FILE: "S3",
      // },
      // goalCountry: "india",
      // countryCode: "IN",
      // countryCodeISO2: "in",
    },
  },
};
