import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Header } from "@onlinesales-ai/header-v2";

import { changeClientId } from "@onlinesales-ai/app-v2/application";
import { redirectUrl } from "src/store/application";
import {
  getDomainConfiguration
} from "src/domainConfig/configuration";

import { routes } from "src/utilities/constants";

import "./index.less";

const AppHeader = ({
  clientId,
  clients,
  isAllClientsFetched,
  onClientChange,
  isShowAllClientsOption,
  isAllClientsSelected,
  isInternalUser,
  showClientList,
  isMobile,
  showNavigation,
  navLinks,
  redirect,
  location,
  shouldBeRendered,
  brandingLogo,
  containerClass,
  brandingClass,
}) => {
  if (!shouldBeRendered) {
    return null;
  }

  const navConfig = navLinks[location.pathname] || navLinks.common;

  return (
    <Header
      containerClass={`small-nav-text ${containerClass}`}
      homePageUrl={routes.DASHBOARD.path}
      clientId={clientId}
      clients={clients}
      onClientChange={(id) => onClientChange(id, getDomainConfiguration)}
      isMobile={isMobile}
      isAllClientsFetched={isAllClientsFetched}
      isShowAllClientsOption={isShowAllClientsOption}
      isAllClientsSelected={isAllClientsSelected}
      isInternalUser={isInternalUser}
      showClientList={false}
      showNavigation={showNavigation}
      navLinks={navConfig}
      redirectUrl={redirect}
      brandingLogo={brandingLogo}
      brandingClass={brandingClass}
    />
  );
};

Header.defaultConfig = {
  showNavigation: true,
  isShowAllClientsOption: false,
};

const mapStateToProps = (state) => {
  const {
    clientId,
    clients,
    isAllClientsFetched,
    isAllClientsSelected,
    isInternalUser,
    isMobile,
  } = state.Application || {};

  const { isShowAllClientsOption, header } = state.DomainConfig || {};

  return {
    location: state.router.location,
    clientId,
    clients,
    isMobile,
    isAllClientsFetched,
    isAllClientsSelected,
    isInternalUser,
    isShowAllClientsOption,
    navLinks: [],
    shouldBeRendered: true,
    ...header,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onClientChange: changeClientId,
      redirect: redirectUrl,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
