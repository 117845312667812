import React, { useState } from "react";
import _uniqWith from "lodash/uniqWith";
import _differenceWith from "lodash/differenceWith";
import Fuse from "fuse.js";

import GlobalSearchContext from "./globalSearchContext";

// [
//   {
//     group: "SPA",
//     title: "Growth Console",
//     subTitle: "SPA / Analytics",
//     url: "/spa/analytics/growthConsole",
//     keywords: ["a", "b"],
//   },
//   {
//     group: "SPA",
//     title: "Merchant Snapshot",
//     subTitle: "SPA / Analytics / Growth Console",
//     url: "/spa/analytics/growthConsole#merchantSnapshot",
//   },
//   {
//     group: "Settings",
//     title: "Logout",
//     subTitle: "Settings",
//     event: "LOGOUT",
//   },
// ];

const GlobalSearchProvider = ({ children }) => {
  const [searchContentList, setSearchContentList] = useState([]);
  const [exploreFeatureList, setExploreFeatureList] = useState([]);

  const compareFunction = (a, b) => {
    if (a.url && b.url) {
      return a.url === b.url;
    }

    if (a.event && b.event) {
      return a.event === b.event;
    }

    return false;
  };

  const addSearchContent = (list = []) => {
    const preparedList = list.map((l) => ({
      ...l,
      searchString: `${l.title} ${l.subTitle}`.trim(),
    }));
    setSearchContentList((old) => _uniqWith([...old, ...preparedList], compareFunction));
  };

  const removeSearchContent = (list = []) => {
    setSearchContentList((old) => _differenceWith(old, list, compareFunction));
  };

  const addExploreContent = (list = []) => {
    setExploreFeatureList((old) => _uniqWith([...old, ...list], compareFunction));
  };

  const removeExploreContent = (list = []) => {
    setExploreFeatureList((old) => _differenceWith(old, list, compareFunction));
  };

  const getFilteredList = (searchTerm = "") => {
    const fuse = new Fuse(searchContentList, {
      useExtendedSearch: true,
      threshold: 0.3,
      keys: [
        {
          name: "searchString",
          weight: 3,
        },
        {
          name: "keywords",
          weight: 2,
        },
        {
          name: "title",
          weight: 1,
        },
        {
          name: "subTitle",
          weight: 1,
        },
      ],
    });

    const result = fuse.search(searchTerm);

    return result;
  };

  return (
    <GlobalSearchContext.Provider
      value={{
        searchContentList,
        setSearchContentList,
        addSearchContent,
        removeSearchContent,
        getFilteredList,
        exploreFeatureList,
        setExploreFeatureList,
        addExploreContent,
        removeExploreContent,
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  );
};

export default GlobalSearchProvider;
