import _keyBy from "lodash/keyBy";
import Types from "./types";

const reducer = (state = {}, action, initialState = {}) => {
  switch (action.type) {
    case Types.SET_PULSE_TICKET_DATA:
      {
        const tickets = _keyBy(action.data, "ticketMetadata.ticketId");
        state = {
          ...initialState,
          ticketing: {
            ...(initialState?.ticketing || {}),
            [action.projectType]: tickets,
          },
        };
      }
      break;
    case Types.SET_SINGLE_PULSE_TICKET_DATA:
      {
        const ticket = _keyBy(action.data, "ticketMetadata.ticketId");
        state = {
          ...initialState,
          ticketing: {
            ...(initialState?.ticketing || {}),
            [action.projectType]: {
              ...(initialState?.ticketing?.[action.projectType] || {}),
              ...ticket,
            },
          },
        };
      }
      break;

    default:
      break;
  }
  return state;
};

export default {
  Support: reducer,
};
