import SharedReducer from "@onlinesales-ai/app-v2/brandAds/reducer";
import { customMergeOS } from "@onlinesales-ai/util-methods-v2";
import _uniq from "lodash/uniq";

import Types from "./types";

const initialState = {
  campaigns: {},
  campaignsFetchInProgress: false,
  campaignsFetchError: false,
  comments: {},
  isReviewerEmailListFetched: false,
  reviewerEmailList: [],
  inventoryData: {},
  goalDetails: {},
  inventorySlotStatus: {},
  pageTypes: {},
};

const reducer = (state = initialState, action) => {
  let hasChanged = true;
  switch (action.type) {
    case Types.BRAND_ADS_FETCH_INPROGRESS:
      {
        state = {
          ...state,
          campaignsFetchInProgress: action.isLoading,
        };
      }
      break;
    case Types.BRAND_ADS_FETCH_ERROR:
      {
        state = {
          ...state,
          campaignsFetchError: action.errorMsg,
        };
      }
      break;
    case Types.BRAND_ADS_UPDATE_CAMPAIGNS:
      {
        // if (action.campaigns && action.campaigns.length > 0) {
        const finalData = customMergeOS({}, state?.campaigns, action?.campaigns);
        state = {
          ...state,
          campaigns: finalData || {},
        };
        // }
      }
      break;
    case Types.BRAND_ADS_SET_REVIEWER_DETAILS_OPTIONS:
      {
        state = {
          ...state,
          reviewerDetailsOptions: action.options || {},
        };
      }
      break;
    case Types.CAMPAIGN_SORTED_IDS:
      {
        state = {
          ...state,
          sortedCampaignIds: action?.ids || [],
        };
      }
      break;
    case Types.UPDATE_CAMPAIGN_SORTED_IDS:
      {
        state = {
          ...state,
          sortedCampaignIds: _uniq([...(state?.sortedCampaignIds || []), ...(action?.ids || [])]),
        };
      }
      break;
    case Types.BRAND_ADS_UPDATE_CAMPAIGN_STATUS:
      {
        state = {
          ...state,
          campaigns: {
            ...(state.campaigns || {}),
            [action.campaignId]: {
              ...(state.campaigns?.[action.campaignId] || {}),
              status: action.status,
              campaignStatus: action.status,
            },
          },
        };
      }
      break;
    case Types.BRAND_ADS_SET_CAMPAIGN_COMMENTS:
      {
        let { goalId, comments } = action;
        if (comments.length) {
          comments = comments.reverse();
        }
        state = {
          ...state,
          comments: {
            ...(state.comments || {}),
            [goalId]: comments,
          },
        };
      }
      break;
    case Types.BRAND_ADS_ADD_CAMPAIGN_COMMENT:
      {
        const { goalId, commentObj } = action;

        state = {
          ...state,
          comments: {
            ...(state.comments || {}),
            [goalId]: [...(state.comments?.[goalId] || []), commentObj],
          },
        };
        // if (!state.comments[goalId]) {
        //   state.comments[goalId] = [];
        // }
        // state.comments[goalId].push(commentObj);
      }
      break;
    case Types.BRAND_ADS_SET_REVIEWER_EMAIL_LIST:
      {
        state = {
          ...state,
          reviewerEmailList: action.reviewerEmailList || [],
        };
      }
      break;
    case Types.BRAND_ADS_SET_IS_REVIEWER_EMAIL_LIST_FETCHED:
      {
        state = {
          ...state,
          isReviewerEmailListFetched: action.isReviewerEmailListFetched || false,
        };
      }
      break;
    default:
      hasChanged = false;
      break;
  }
  if (!hasChanged) {
    return SharedReducer.BrandAds(state, action, initialState);
  }

  return state;
};

export default {
  BrandAds: reducer,
};
