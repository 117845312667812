import React, { useEffect, useMemo, useState, useRef } from "react";
import { connect } from "react-redux";
import StackGrid, { transitions } from "react-stack-grid";
import _find from "lodash/find";
import _debounce from "lodash/debounce";

import classnames from "classnames";

import VideoPlayer from "@onlinesales-ai/video-player-v2";
import { ImagePreviewModal } from "@onlinesales-ai/utils-components-v2";
import { FetchBanner, EmptyMessage } from "@onlinesales-ai/message-card-v2";

import "./index.less";

const { fadeDown: animationToUse } = transitions;

const MediaMosaicView = ({
  mediaList = [],
  mediaDimension,
  selectedData,
  isSelectable = false,
  onSelect = () => {},
  errorMsg,
  isLoading,
  emptyMsg,
  mediaExtraParams,
  haveMore = true,
  onFetchNextBatch,
  mediaDataDOM,
  mediaOptionsDOM,
  checkCardDisabled,
  isMobile,
  loaderSize,
  columnWidth,
  enableAspectRatio,
  hideNoPreview,
  containerClass,
  videoPlayerProps,
}) => {
  const [previewUrl, setPreviewUrl] = useState("");
  const lazyLoadRef = useRef();
  const lazyLoadObserver = useRef();
  const [intersectionCounter, setIntersectionCounter] = useState(0);
  const isMount = useRef(false);
  const isLoadingRef = useRef(false);
  const errorMsgRef = useRef(null);
  const gridRef = useRef(null);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    errorMsgRef.current = errorMsg;
  }, [errorMsg]);

  useEffect(() => {
    if (lazyLoadRef.current) {
      lazyLoadObserver.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!isLoadingRef.current && !errorMsgRef.current) {
              setIntersectionCounter((prev) => (prev + 1));
            }
          }
        });
      });

      lazyLoadObserver.current.observe(lazyLoadRef.current);

      return () => {
        if (lazyLoadObserver.current && lazyLoadRef.current) {
          lazyLoadObserver.current.unobserve(lazyLoadRef.current);
        }
      };
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (haveMore && isMount.current) {
      onFetchNextBatch();
    }
    isMount.current = true;
  }, [intersectionCounter]);

  const updateGridLayout = () => {
    gridRef.current?.updateLayout();
  };

  const updateGridDebounce = _debounce(updateGridLayout, 300);

  const onVideoLoad = () => {
    updateGridDebounce();
  };

  const renderLoader = (isOnlyItems = false) => {
    let items = [
      <div className="column-one">
        <div className="animated-bg" />
        <div className="animated-bg" />
        <div className="animated-bg" />
      </div>,
      <div className="column-two">
        <div className="animated-bg" />
        <div className="animated-bg" />
        <div className="animated-bg" />
      </div>,
      <div className="column-three">
        <div className="animated-bg" />
        <div className="animated-bg" />
        <div className="animated-bg" />
      </div>,
    ];

    if (loaderSize === "small") {
      items = [
        <div className="column-one">
          <div className="animated-bg" />
          <div className="animated-bg" />
        </div>,
        <div className="column-two">
          <div className="animated-bg" />
        </div>,
        <div className="column-three">
          <div className="animated-bg" />
          <div className="animated-bg" />
        </div>,
      ];
    }

    if (isOnlyItems) {
      return items;
    } else {
      return (
        <div className="library-wrapper-loader card-uneven-column">
          {items}
        </div>
      );
    }
  };

  const width = useMemo(() => {
    if (columnWidth) {
      if (isMobile) {
        return "100%";
      }

      return columnWidth;
    }
    if (isMobile) {
      return "100%";
    }

    if (mediaDimension) {
      const dimensionList = mediaDimension.split(" x ");

      if (mediaList?.length <= 0) {
        return "100%";
      } else if (dimensionList?.[0] > 1900 && dimensionList?.[1] > 600) {
        return "50%";
      } else if (dimensionList?.[0] > 1200) {
        return "100%";
      } else if (dimensionList?.[0] > 400) {
        return "50%";
      } else if (dimensionList?.[0] > 200) {
        return "33.33%";
      } else if (dimensionList?.[0] > 175) {
        return "20%";
      } else if (dimensionList?.[0] >= 60) {
        return "16.66%";
      }
    }
    if (!mediaList?.length) {
      return "100%";
    }
    return "33.33%";
  }, [mediaList, columnWidth]);

  const aspectWidth = useMemo(() => {
    return mediaDimension?.split("x")?.[0];
  }, [mediaDimension]);

  const [showPreview, setShowPreview] = useState(false);

  const hidePreviewModal = () => {
    setShowPreview(false);
    setPreviewUrl(null);
  };

  return (
    <div className={classnames("media-list-mosaic-view-container", containerClass)}>
      <FetchBanner
        Loader={renderLoader()}
        errorMsg={errorMsg}
        errorTitle="Error occurred while fetching media."
        isLoading={!mediaList.length && isLoading}
      >
        <ImagePreviewModal
          title="Image Preview"
          isShow={showPreview}
          list={previewUrl ? [previewUrl] : []}
          className="media-image-preview-modal-wrapper"
          onClose={hidePreviewModal}
        />
        {Array.isArray(mediaList) && mediaList?.length ? (
          <StackGrid
            className="media-list-mosaic-view"
            gutterWidth={20}
            gutterHeight={20}
            columnWidth={width}
            appear={animationToUse.appear}
            appeared={animationToUse.appeared}
            enter={animationToUse.enter}
            entered={animationToUse.entered}
            leaved={animationToUse.leaved}
            monitorImagesLoaded
            gridRef={(grid) => (gridRef.current = grid)}
            duration={0}
          >
            {mediaList.map((mediaObj, index) => {
              const {
                url,
                thumbnailUrl,
                type,
                userTags = [],
                height,
                sizeInBytes,
                width: widthLocal,
                ...rest
              } = mediaObj;
              const isSelected = _find(selectedData, {
                tabType: type,
                url,
                ...mediaExtraParams,
              });

              const isDisabled = checkCardDisabled ? checkCardDisabled(mediaObj) : false;

              return (
                <div
                  key={url}
                  className={`media-item card-uneven-inner 
                  ${isSelected ? "selected" : "not-selected"}
                  ${isDisabled ? " disabled " : ""}
                  ${isSelectable && !isDisabled ? "cursor-pointer" : ""}
                  `}
                  onClick={
                    isSelectable && !isDisabled
                      ? () =>
                          onSelect(url, { type, height, width: widthLocal, sizeInBytes, mediaObj })
                      : () => {}
                  }
                  style={{ aspectRatio: enableAspectRatio ? `${aspectWidth} / ${height}` : "auto" }}
                >
                  {type === "IMAGE" ? (
                    <>
                      <img className="os-async-img-container__" src={url} alt="" />
                      <div className="preview-image">
                        <span
                          className="icon icon-expand1"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setPreviewUrl(url);
                            setShowPreview(true);
                          }}
                        />
                      </div>
                      <div className="media-overlay" />
                    </>
                  ) : (
                    <>
                      {type === "VIDEO" ? (
                        <VideoPlayer
                          enableWrapperClick
                          allowPlayOnHover
                          shouldShowControls={false}
                          src={url}
                          thumbnailUrl={thumbnailUrl}
                          showPreview
                          showCloseOnTop
                          onVideoLoad={onVideoLoad}
                          addOverlayDom
                          hideNoPreview={hideNoPreview}
                          {...videoPlayerProps}
                        />
                      ) : null}
                    </>
                  )}
                  {isSelected && <div className="plan-select icon-check " />}
                  {mediaDataDOM({ height, width: widthLocal, sizeInBytes, ...rest })}
                  {mediaOptionsDOM({ userTags, url, ...rest }, index)}
                </div>
              );
            })}
            {!!mediaList.length && isLoading && renderLoader(true)}
          </StackGrid>
        ) : (
          <EmptyMessage>{emptyMsg}</EmptyMessage>
        )}
      </FetchBanner>
      <div className="lazy-load-trigger" ref={lazyLoadRef} />
    </div>
  );
};

MediaMosaicView.defaultProps = {
  emptyMsg: "No Media Found",
  mediaExtraParams: {},
  onFetchNextBatch: () => {},
  mediaOptionsDOM: () => {},
  mediaDataDOM: () => {},
  loaderSize: "default",
  enableAspectRatio: false,
};

const mapStateToProps = (state) => {
  return {
    isMobile: state.Application.isMobile,
  };
};

export default connect(mapStateToProps)(MediaMosaicView);
