import { getDomainConfig } from "@onlinesales-ai/domain-config-v2";
import { getLSItem } from "@onlinesales-ai/util-methods-v2";

import BaseConfig from "./configs/baseConfig";

export const configOverrides = {
  "appslocal-dev": {
    name: "osmos-sandbox-pulse.json",
  },

  // Staging ==========

  "staging-apps": {
    name: "monetize-sandbox-pulse.json",
  },
  "staging-v1-admin": {
    name: "takealot-pulse.json",
  },
  "staging-v3-apps": {
    name: "takealot-pulse.json",
  },
  "staging-v4-apps": {
    name: "hotstar-staging-admin.json",
  },
  "staging-v6-apps": {
    name: "takealot-admin.json",
  },
  "staging-v7-apps": {
    name: "monetize-sandbox-pulse.json",
  },
  "staging-v8-apps": {
    name: "hotstar-staging-admin.json",
  },
  "staging-v9-apps": {
    name: "takealot-pulse.json",
  },
  "staging-v10-apps": {
    name: "takealot-admin.json",
  },
  "monetize-staging-1": {
    name: "monetize-sandbox-pulse.json",
  },
  "monetize-staging-2": {
    name: "monetize-sandbox-pulse.json",
  },
  "monetize-staging-3": {
    name: "monetize-sandbox-pulse.json",
  },
  "monetize-staging-4": {
    name: "monetize-sandbox-pulse.json",
  },
  "monetize-staging-5": {
    name: "monetize-sandbox-pulse.json",
  },
  "monetize-staging-6": {
    name: "monetize-sandbox-pulse.json",
  },
  "monetize-staging-7": {
    name: "purplle-pulse.json",
  },
  "monetize-staging-8": {
    name: "tving-dev-pulse.json",
  },
  "osmos-staging-3": {
    name: "monetize-sandbox-pulse.json",
  },
  "osmos-staging-14": {
    name: "monetize-sandbox-pulse.json",
  },
  "monetize-staging-9": {
    name: "dunnhumby-sandbox-pulse.json",
  },
  "osmos-qa-staging-1": {
    name: "qa-marketplace-pulse.json",
  },
  "osmos-qa-staging-2": {
    name: "qa-marketplace-pulse.json",
  },
  "osmos-qa-staging-3": {
    name: "qa-marketplace-pulse.json",
  },
  "oms-qa-staging-2": {
    name: "tving-dev-pulse.json",
  },
  "oms-qa-staging-3": {
    name: "tving-dev-pulse.json",
  },
  "osmos-staging-12": {
    name: "monetize-sandbox-pulse.json",
  },
  "osmos-staging-13": {
    name: "monetize-sandbox-pulse.json",
  },

  // staging ending ********

  // In Used Clients =====

  // Wakefern ===

  "wakefern-admin": {
    name: "wakefern-admin.json",
  },
  "wakefern-staging-admin": {
    name: "wakefern-staging-admin.json",
  },

  // Takealot ====

  "takealot-admin": {
    name: "takealot-admin.json",
  },
  "takealot-staging-admin": {
    name: "takealot-staging-admin.json",
  },
  "takealot-admin-new": {
    name: "takealot-admin.json",
  },

  // Purplle ===

  "purplle-admin": {
    name: "purplle-admin.json",
  },
  "purplle-staging-admin": {
    name: "purplle-staging-admin.json",
  },
  "purplle-admin-new": {
    name: "purplle-admin.json",
  },

  // Hotstar ===

  "hotstar-admin": {
    name: "hotstar-admin.json",
  },
  "hotstar-staging-admin": {
    name: "hotstar-staging-admin.json",
  },
  "publisher-demo-admin": {
    name: "publisher-demo-admin.json",
  },
  "hotstar-prod-admin": {
    name: "hotstar-prod-admin.json",
  },
  "hotstar-prod-test-admin": {
    name: "hotstar-prod-test-admin.json",
  },
  "hotstar-sd-prod-admin": {
    name: "hotstar-sd-prod-admin.json",
  },
  "hotstar-sd-admin": {
    name: "hotstar-sd-admin.json",
  },

  // sandbox ===

  "monetize-sandbox-admin": {
    name: "monetize-sandbox-admin.json",
  },
  "target-admin": {
    name: "target-admin.json",
  },
  "target-staging-admin": {
    name: "target-staging-admin.json",
  },

  // 1MG ===

  "1mg-admin": {
    name: "1mg-admin.json",
  },
  "1mg-staging-admin": {
    name: "1mg-staging-admin.json",
  },

  // Lamoda ===

  "lamoda-admin": {
    name: "lamoda-admin.json",
  },
  "lamoda-staging-admin": {
    name: "lamoda-staging-admin.json",
  },

  // Konga ===

  "konga-admin-new": {
    name: "konga-admin.json",
  },
  "konga-admin": {
    name: "konga-admin.json",
  },

  // Limeroad ===

  "limeroad-admin": {
    name: "limeroad-admin.json",
  },
  "limeroad-staging-admin": {
    name: "limeroad-staging-admin.json",
  },

  // Shopmatic ===

  "shopmatic-admin": {
    name: "shopmatic-admin.json",
  },

  "shopmatic-staging-admin": {
    name: "shopmatic-staging-admin.json",
  },

  // Zepto domains ===

  "zepto-admin": {
    name: "zepto-admin.json",
  },
  "zepto-staging-admin": {
    name: "zepto-staging-admin.json",
  },

  // MRD domains ===

  "mrd-admin": {
    name: "mrd-admin.json",
  },
  "mrd-staging-admin": {
    name: "mrd-staging-admin.json",
  },

  // Kornershopee domains ===

  "kornershoppe-admin": {
    name: "kornershoppe-admin.json",
  },
  "kornershoppe-prod-admin": {
    name: "kornershoppe-prod-admin.json",
  },

  // sharaf ===

  "sharafdg-admin": {
    name: "sharafdg-admin.json",
  },
  "sharafdg-staging-admin": {
    name: "sharafdg-staging-admin.json",
  },

  // sharaf bahrain ===

  "sharafdg-bahrain-admin": {
    name: "sharafdg-bahrain-admin.json",
  },
  "sharafdg-bahrain-staging-admin": {
    name: "sharafdg-bahrain-staging-admin.json",
  },

  // GlowRoad ===

  "glowroad-admin": {
    name: "glowroad-admin.json",
  },
  "glowroad-staging-admin": {
    name: "glowroad-staging-admin.json",
  },

  // inmar domains ===

  "inmarads-rmn-admin": {
    name: "inmarads-rmn-admin.json",
  },
  "inmarads-retailer-admin": {
    name: "inmarads-retailer-admin.json",
  },
  "inmarads-demo-admin": {
    name: "inmarads-demo-admin.json",
  },
  "demo-retailcloud-admin.inmar.com": {
    name: "inmarads-demo-admin.json",
  },

  "master-admin": {
    name: "master-admin.json",
  },
  "ads-admin.retailcloud.inmar.com": {
    name: "rmn-retailcloud-admin.json",
  },

  "tops-admin.retailcloud.inmar.com": {
    name: "tops-market-admin.json",
  },

  // Fanatics domains ===

  "fanatics-admin": {
    name: "fanatics-admin.json",
  },
  "fanatics-staging-admin": {
    name: "fanatics-staging-admin.json",
  },

  // Tops Market domains ===

  "tops-market-admin": {
    name: "tops-market-admin.json",
  },
  "tops-market-staging-admin": {
    name: "tops-market-staging-admin.json",
  },

  // snapdeal domains ===

  "snapdeal-admin": {
    name: "snapdeal-admin.json",
  },
  "snapdeal-staging-admin": {
    name: "snapdeal-staging-admin.json",
  },
  "retail-cloud-admin.ia.ooo": {
    name: "iaooo-admin.json",
  },
  "iaooo-admin.json": {
    name: "iaooo-admin.json",
  },

  // Pick n Pay

  "picknpay-admin": {
    name: "picknpay-admin.json",
  },
  "picknpay-staging-admin": {
    name: "picknpay-staging-admin.json",
  },

  // Senheng

  "senheng-admin": {
    name: "senheng-admin.json",
  },
  "senheng-staging-admin": {
    name: "senheng-staging-admin.json",
  },

  // FirstCry

  "firstcry-admin": {
    name: "firstcry-admin.json",
  },
  "firstcry-staging-admin": {
    name: "firstcry-staging-admin.json",
  },

  // End Of used Clients =====

  // Not In Used ===

  // Paytm ===
  "paytm-admin": {
    name: "paytm-admin.json",
  },
  "paytm-admin-new": {
    name: "paytm-admin.json",
  },

  "marketplace-admin": {
    name: "marketplace-admin.json",
  },

  // Tving Prod

  "oms-adops.tving.com": {
    name: "tving-prod-pulse.json",
  },
  "tving-prod-pulse": {
    name: "tving-prod-pulse.json",
  },
  "tving-prod-staging-pulse": {
    name: "tving-prod-staging-pulse.json",
  },

  // Tving QA

  "oms-dev-adops.tving.com": {
    name: "tving-qa-pulse.json",
  },
  "tving-qa-pulse": {
    name: "tving-qa-pulse.json",
  },

  // Tving Dev

  "tving-dev-pulse": {
    name: "tving-dev-pulse.json",
  },

  // End not in Used

  // ########################
  //       Pulse
  // ########################
  "takealot-pulse": {
    name: "takealot-pulse.json",
  },
  "takealot-staging-pulse": {
    name: "takealot-staging-pulse.json",
  },
  "monetize-sandbox-pulse": {
    name: "monetize-sandbox-pulse.json",
  },
  "osmos-sandbox-pulse": {
    name: "osmos-sandbox-pulse.json",
  },
  "glowroad-pulse": {
    name: "glowroad-pulse.json",
  },
  "glowroad-staging-pulse": {
    name: "glowroad-staging-pulse.json",
  },
  "1mg-pulse": {
    name: "1mg-pulse.json",
  },
  "1mg-staging-pulse": {
    name: "1mg-staging-pulse.json",
  },
  "zepto-pulse": {
    name: "zepto-pulse.json",
  },
  "zepto-staging-pulse": {
    name: "zepto-staging-pulse.json",
  },
  "konga-pulse": {
    name: "konga-pulse.json",
  },
  "lamoda-pulse": {
    name: "lamoda-pulse.json",
  },
  "lamoda-staging-pulse": {
    name: "lamoda-staging-pulse.json",
  },
  "mrd-pulse": {
    name: "mrd-pulse.json",
  },
  "mrd-staging-pulse": {
    name: "mrd-staging-pulse.json",
  },
  "picknpay-pulse": {
    name: "picknpay-pulse.json",
  },
  "picknpay-staging-pulse": {
    name: "picknpay-staging-pulse.json",
  },
  "purplle-pulse": {
    name: "purplle-pulse.json",
  },
  "purplle-staging-pulse": {
    name: "purplle-staging-pulse.json",
  },
  "senheng-pulse": {
    name: "senheng-pulse.json",
  },
  "senheng-staging-pulse": {
    name: "senheng-staging-pulse.json",
  },
  "sharafdg-bahrain-pulse": {
    name: "sharafdg-bahrain-pulse.json",
  },
  "sharafdg-bahrain-staging-pulse": {
    name: "sharafdg-bahrain-staging-pulse.json",
  },
  "sharafdg-pulse": {
    name: "sharafdg-pulse.json",
  },
  "sharafdg-staging-pulse": {
    name: "sharafdg-staging-pulse.json",
  },
  "snapdeal-pulse": {
    name: "snapdeal-pulse.json",
  },
  "snapdeal-staging-pulse": {
    name: "snapdeal-staging-pulse.json",
  },
  "tops-market-pulse": {
    name: "tops-market-pulse.json",
  },
  "tops-market-staging-pulse": {
    name: "tops-market-staging-pulse.json",
  },
  "kornershoppe-pulse": {
    name: "kornershoppe-pulse.json",
  },
  "kornershoppe-prod-pulse": {
    name: "kornershoppe-prod-pulse.json",
  },
  "firstcry-pulse": {
    name: "firstcry-pulse.json",
  },
  "firstcry-staging-pulse": {
    name: "firstcry-staging-pulse.json",
  },
  "wakefern-pulse": {
    name: "wakefern-pulse.json",
  },
  "wakefern-staging-pulse": {
    name: "wakefern-staging-pulse.json",
  },
  "fanatics-pulse": {
    name: "fanatics-pulse.json",
  },
  "fanatics-staging-pulse": {
    name: "fanatics-staging-pulse.json",
  },
  "dawaadost-pulse": {
    name: "dawaadost-pulse.json",
  },
  "dawaadost-staging-pulse": {
    name: "dawaadost-staging-pulse.json",
  },
  "superbalist-pulse": {
    name: "superbalist-pulse.json",
  },
  "superbalist-staging-pulse": {
    name: "superbalist-staging-pulse.json",
  },
  "ajio-pulse": {
    name: "ajio-pulse.json",
  },
  "ajio-staging-pulse": {
    name: "ajio-staging-pulse.json",
  },
  "tesco-pulse": {
    name: "tesco-pulse.json",
  },
  "tesco-staging-pulse": {
    name: "tesco-staging-pulse.json",
  },
  "dunnhumby-uk-pulse": {
    name: "dunnhumby-uk-pulse.json",
  },
  "dunnhumby-uk-staging-pulse": {
    name: "dunnhumby-uk-staging-pulse.json",
  },
  "dunnhumby-us-pulse": {
    name: "dunnhumby-us-pulse.json",
  },
  "dunnhumby-us-staging-pulse": {
    name: "dunnhumby-us-staging-pulse.json",
  },
  "northeast-grocery-pulse": {
    name: "northeast-grocery-pulse.json",
  },
  "northeast-grocery-staging-pulse": {
    name: "northeast-grocery-staging-pulse.json",
  },
  "konvy-pulse": {
    name: "konvy-pulse.json",
  },
  "konvy-staging-pulse": {
    name: "konvy-staging-pulse.json",
  },
  "limeroad-pulse": {
    name: "limeroad-pulse.json",
  },
  "limeroad-staging-pulse": {
    name: "limeroad-staging-pulse.json",
  },
  "qa-grocery-pulse": {
    name: "qa-grocery-pulse.json",
  },
  "qa-marketplace-pulse": {
    name: "qa-marketplace-pulse.json",
  },
  "rapido-pulse": {
    name: "rapido-pulse.json",
  },
  "rapido-staging-pulse": {
    name: "rapido-staging-pulse.json",
  },
  "croma-pulse": {
    name: "croma-pulse.json",
  },
  "croma-staging-pulse": {
    name: "croma-staging-pulse.json",
  },
  "truemeds-pulse": {
    name: "truemeds-pulse.json",
  },
  "truemeds-staging-pulse": {
    name: "truemeds-staging-pulse.json",
  },
  "tatacliq-pulse": {
    name: "tatacliq-pulse.json",
  },
  "tatacliq-staging-pulse": {
    name: "tatacliq-staging-pulse.json",
  },
  "lidl-pulse": {
    name: "lidl-pulse.json",
  },
  "lidl-staging-pulse": {
    name: "lidl-staging-pulse.json",
  },
  "apollo-247-pulse": {
    name: "apollo-247-pulse.json",
  },
  "apollo-247-staging-pulse": {
    name: "apollo-247-staging-pulse.json",
  },
  "medibuddy-pulse": {
    name: "medibuddy-pulse.json",
  },
  "medibuddy-staging-pulse": {
    name: "medibuddy-staging-pulse.json",
  },
  "homepro-pulse": {
    name: "homepro-pulse.json",
  },
  "homepro-staging-pulse": {
    name: "homepro-staging-pulse.json",
  },
  "retailer-pulse": {
    name: "retailer-pulse.json",
  },
  "retailer-staging-pulse": {
    name: "retailer-staging-pulse.json",
  },
  "tata-digital-pulse": {
    name: "tata-digital-pulse.json",
  },
  "tata-digital-staging-pulse": {
    name: "tata-digital-staging-pulse.json",
  },
  "dunnhumby-sandbox-pulse": {
    name: "dunnhumby-sandbox-pulse.json",
  },
  "demo-retailer.dhretailmedia.com": {
    name: "dunnhumby-sandbox-pulse.json",
  },
  "dunnhumby-sandbox-pulse-os": {
    name: "dunnhumby-sandbox-pulse.json",
  },
};

const stagingDomains = [
  "appslocal-dev.onlinesales.ai",
  "staging-v1-admin.onlinesales.ai",
  "staging-v3-apps.onlinesales.ai",
  "staging-v4-apps.onlinesales.ai",
  "staging-v6-apps.onlinesales.ai",
  "staging-v3-apps.onlinesales.ai",
  "staging-apps.onlinesales.ai",
  "staging-v4-apps.onlinesales.ai",
  "staging-v6-apps.onlinesales.ai",
  "staging-v8-apps.onlinesales.ai",
  "staging-v9-apps.onlinesales.ai",
  "staging-v10-apps.onlinesales.ai",
  "monetize-staging-1.onlinesales.ai",
  "monetize-staging-2.onlinesales.ai",
  "monetize-staging-3.onlinesales.ai",
  "monetize-staging-4.onlinesales.ai",
  "monetize-staging-5.onlinesales.ai",
  "monetize-staging-6.onlinesales.ai",
  "monetize-staging-7.onlinesales.ai",
  "monetize-staging-8.onlinesales.ai",
  "monetize-staging-9.onlinesales.ai",
  "osmos-qa-staging-1.onlinesales.ai",
  "osmos-qa-staging-2.onlinesales.ai",
  "osmos-qa-staging-3.onlinesales.ai",
  "oms-qa-staging-2.onlinesales.ai",
  "oms-qa-staging-3.onlinesales.ai",
  "osmos-staging-12.onlinesales.ai",
  "osmos-staging-13.onlinesales.ai",
];

export const isStagingDomain = stagingDomains.includes(window?.location?.hostname);

export const lngDomains = [
  ...stagingDomains,
  "oms-adops.tving.com",
  "oms-dev-adops.tving.com",
  "tving-qa-pulse.onlinesales.ai",
  "tving-dev-pulse.onlinesales.ai",
  "tving-prod-staging-pulse.onlinesales.ai",
  "tving-prod-pulse.onlinesales.ai",
  "oms-qa-staging-2.onlinesales.ai",
  "oms-qa-staging-3.onlinesales.ai",
];

export const isLngDomain = lngDomains.includes(window?.location?.hostname);

const configs = {
  "base.json": BaseConfig,
  "konga-admin.json": () => import("./configs/konga-admin"),
  "paytm-admin.json": () => import("./configs/paytm-admin"),
  "purplle-admin.json": () => import("./configs/purplle-admin"),
  "purplle-staging-admin.json": () => import("./configs/purplle-staging-admin"),
  "wakefern-admin.json": () => import("./configs/wakefern-admin"),
  "wakefern-staging-admin.json": () => import("./configs/wakefern-staging-admin"),
  "lamoda-admin.json": () => import("./configs/lamoda-admin"),
  "lamoda-staging-admin.json": () => import("./configs/lamoda-staging-admin"),
  "takealot-admin.json": () => import("./configs/takealot-admin"),
  "takealot-staging-admin.json": () => import("./configs/takealot-staging-admin"),
  "marketplace-admin.json": () => import("./configs/marketplace-admin"),
  "monetize-sandbox-admin.json": () => import("./configs/monetize-sandbox-admin"),
  "hotstar-admin.json": () => import("./configs/hotstar-admin"),
  "hotstar-staging-admin.json": () => import("./configs/hotstar-staging-admin"),
  "publisher-demo-admin.json": () => import("./configs/publisher-demo-admin"),
  "hotstar-prod-test-admin.json": () => import("./configs/hotstar-prod-test-admin"),
  "hotstar-prod-admin.json": () => import("./configs/hotstar-prod-admin"),
  "hotstar-sd-prod-admin.json": () => import("./configs/hotstar-sd-prod-admin"),
  "hotstar-sd-admin.json": () => import("./configs/hotstar-sd-admin"),
  "target-admin.json": () => import("./configs/target-admin"),
  "target-staging-admin.json": () => import("./configs/target-staging-admin"),
  "1mg-admin.json": () => import("./configs/1mg-admin"),
  "1mg-staging-admin.json": () => import("./configs/1mg-staging-admin"),
  "limeroad-admin.json": () => import("./configs/limeroad-admin"),
  "limeroad-staging-admin.json": () => import("./configs/limeroad-staging-admin"),
  "shopmatic-admin.json": () => import("./configs/shopmatic-admin"),
  "shopmatic-staging-admin.json": () => import("./configs/shopmatic-staging-admin"),
  "zepto-admin.json": () => import("./configs/zepto-admin"),
  "zepto-staging-admin.json": () => import("./configs/zepto-staging-admin"),
  "mrd-admin.json": () => import("./configs/mrd-admin"),
  "mrd-staging-admin.json": () => import("./configs/mrd-staging-admin"),
  "kornershoppe-admin.json": () => import("./configs/kornershoppe-admin"),
  "kornershoppe-prod-admin.json": () => import("./configs/kornershoppe-prod-admin"),
  "inmarads-rmn-admin.json": () => import("./configs/inmarads-rmn-admin"),
  "inmarads-retailer-admin.json": () => import("./configs/inmarads-retailer-admin"),
  "inmarads-demo-admin.json": () => import("./configs/inmarads-demo-admin"),
  "sharafdg-admin.json": () => import("./configs/sharafdg-admin"),
  "sharafdg-staging-admin.json": () => import("./configs/sharafdg-staging-admin"),
  "sharafdg-bahrain-staging-admin.json": () => import("./configs/sharafdg-bahrain-staging-admin"),
  "sharafdg-bahrain-admin.json": () => import("./configs/sharafdg-bahrain-admin"),
  "glowroad-admin.json": () => import("./configs/glowroad-admin"),
  "glowroad-staging-admin.json": () => import("./configs/glowroad-staging-admin"),
  "rmn-staging-admin.json": () => import("./configs/rmn-staging-admin"),
  "master-admin.json": () => import("./configs/master-admin"),
  "rmn-retailcloud-admin.json": () => import("./configs/rmn-retailcloud-admin"),
  "tops-market-admin.json": () => import("./configs/tops-market-admin"),
  "tops-market-staging-admin.json": () => import("./configs/tops-market-staging-admin"),
  "fanatics-admin.json": () => import("./configs/fanatics-admin"),
  "fanatics-staging-admin.json": () => import("./configs/fanatics-staging-admin"),
  "snapdeal-admin.json": () => import("./configs/snapdeal-admin"),
  "snapdeal-staging-admin.json": () => import("./configs/snapdeal-staging-admin"),
  "iaooo-admin.json": () => import("./configs/iaooo-admin"),
  "picknpay-admin.json": () => import("./configs/picknpay-admin"),
  "picknpay-staging-admin.json": () => import("./configs/picknpay-staging-admin"),
  "senheng-admin.json": () => import("./configs/senheng-admin"),
  "senheng-staging-admin.json": () => import("./configs/senheng-staging-admin"),
  "firstcry-admin.json": () => import("./configs/firstcry-admin"),
  "firstcry-staging-admin.json": () => import("./configs/firstcry-staging-admin"),

  // Pulse Config
  "takealot-pulse.json": () => import("./configs/pulse/domains/takealot"),
  "takealot-staging-pulse.json": () => import("./configs/pulse/domains/takealot-staging"),
  "monetize-sandbox-pulse.json": () => import("./configs/pulse/domains/monetize-sandbox"),
  "osmos-sandbox-pulse.json": () => import("./configs/pulse/domains/osmos-sandbox"),
  "glowroad-pulse.json": () => import("./configs/pulse/domains/glowroad"),
  "glowroad-staging-pulse.json": () => import("./configs/pulse/domains/glowroad-staging"),
  "1mg-pulse.json": () => import("./configs/pulse/domains/1mg"),
  "1mg-staging-pulse.json": () => import("./configs/pulse/domains/1mg-staging"),
  "zepto-pulse.json": () => import("./configs/pulse/domains/zepto"),
  "zepto-staging-pulse.json": () => import("./configs/pulse/domains/zepto-staging"),
  "lamoda-pulse.json": () => import("./configs/pulse/domains/lamoda"),
  "lamoda-staging-pulse.json": () => import("./configs/pulse/domains/lamoda-staging"),
  "mrd-pulse.json": () => import("./configs/pulse/domains/mrd"),
  "mrd-staging-pulse.json": () => import("./configs/pulse/domains/mrd-staging"),
  "picknpay-pulse.json": () => import("./configs/pulse/domains/picknpay"),
  "picknpay-staging-pulse.json": () => import("./configs/pulse/domains/picknpay-staging"),
  "purplle-pulse.json": () => import("./configs/pulse/domains/purplle"),
  "purplle-staging-pulse.json": () => import("./configs/pulse/domains/purplle-staging"),
  "senheng-pulse.json": () => import("./configs/pulse/domains/senheng"),
  "senheng-staging-pulse.json": () => import("./configs/pulse/domains/senheng-staging"),
  "sharafdg-pulse.json": () => import("./configs/pulse/domains/sharafdg"),
  "sharafdg-staging-pulse.json": () => import("./configs/pulse/domains/sharafdg-staging"),
  "sharafdg-bahrain-pulse.json": () => import("./configs/pulse/domains/sharafdg-bahrain"),
  "sharafdg-bahrain-staging-pulse.json": () => import("./configs/pulse/domains/sharafdg-bahrain-staging"),
  "snapdeal-pulse.json": () => import("./configs/pulse/domains/snapdeal"),
  "snapdeal-staging-pulse.json": () => import("./configs/pulse/domains/snapdeal-staging"),
  "tops-market-pulse.json": () => import("./configs/pulse/domains/tops-market"),
  "tops-market-staging-pulse.json": () => import("./configs/pulse/domains/tops-market-staging"),
  "konga-pulse.json": () => import("./configs/pulse/domains/konga"),
  "kornershoppe-pulse.json": () => import("./configs/pulse/domains/kornershoppe"),
  "kornershoppe-prod-pulse.json": () => import("./configs/pulse/domains/kornershoppe-prod"),
  "firstcry-pulse.json": () => import("./configs/pulse/domains/firstcry"),
  "firstcry-staging-pulse.json": () => import("./configs/pulse/domains/firstcry-staging"),
  "wakefern-pulse.json": () => import("./configs/pulse/domains/wakefern"),
  "wakefern-staging-pulse.json": () => import("./configs/pulse/domains/wakefern-staging"),
  "fanatics-pulse.json": () => import("./configs/pulse/domains/fanatics"),
  "fanatics-staging-pulse.json": () => import("./configs/pulse/domains/fanatics-staging"),
  "dawaadost-pulse.json": () => import("./configs/pulse/domains/dawaadost"),
  "dawaadost-staging-pulse.json": () => import("./configs/pulse/domains/dawaadost-staging"),
  "superbalist-pulse.json": () => import("./configs/pulse/domains/superbalist"),
  "superbalist-staging-pulse.json": () => import("./configs/pulse/domains/superbalist-staging"),
  "tving-prod-pulse.json": () => import("./configs/pulse/domains/tving-prod"),
  "tving-prod-staging-pulse.json": () => import("./configs/pulse/domains/tving-prod-staging"),
  "tving-qa-pulse.json": () => import("./configs/pulse/domains/tving-qa"),
  "tving-dev-pulse.json": () => import("./configs/pulse/domains/tving-dev"),
  "ajio-pulse.json": () => import("./configs/pulse/domains/ajio"),
  "ajio-staging-pulse.json": () => import("./configs/pulse/domains/ajio-staging"),
  "tesco-pulse.json": () => import("./configs/pulse/domains/tesco"),
  "tesco-staging-pulse.json": () => import("./configs/pulse/domains/tesco-staging"),
  "dunnhumby-uk-pulse.json": () => import("./configs/pulse/domains/dunnhumby-uk"),
  "dunnhumby-uk-staging-pulse.json": () => import("./configs/pulse/domains/dunnhumby-uk-staging"),
  "dunnhumby-us-pulse.json": () => import("./configs/pulse/domains/dunnhumby-us"),
  "dunnhumby-us-staging-pulse.json": () => import("./configs/pulse/domains/dunnhumby-us-staging"),
  "northeast-grocery-staging-pulse.json": () => import("./configs/pulse/domains/northeast-grocery-staging"),
  "northeast-grocery-pulse.json": () => import("./configs/pulse/domains/northeast-grocery"),
  "konvy-pulse.json": () => import("./configs/pulse/domains/konvy"),
  "konvy-staging-pulse.json": () => import("./configs/pulse/domains/konvy-staging"),
  "limeroad-pulse.json": () => import("./configs/pulse/domains/limeroad"),
  "limeroad-staging-pulse.json": () => import("./configs/pulse/domains/limeroad-staging"),
  "qa-grocery-pulse.json": () => import("./configs/pulse/domains/qa-grocery"),
  "qa-marketplace-pulse.json": () => import("./configs/pulse/domains/qa-marketplace"),
  "rapido-pulse.json": () => import("./configs/pulse/domains/rapido"),
  "rapido-staging-pulse.json": () => import("./configs/pulse/domains/rapido-staging"),
  "croma-pulse.json": () => import("./configs/pulse/domains/croma"),
  "croma-staging-pulse.json": () => import("./configs/pulse/domains/croma-staging"),
  "truemeds-pulse.json": () => import("./configs/pulse/domains/truemeds"),
  "truemeds-staging-pulse.json": () => import("./configs/pulse/domains/truemeds-staging"),
  "tatacliq-pulse.json": () => import("./configs/pulse/domains/tatacliq"),
  "tatacliq-staging-pulse.json": () => import("./configs/pulse/domains/tatacliq-staging"),
  "lidl-pulse.json": () => import("./configs/pulse/domains/lidl"),
  "lidl-staging-pulse.json": () => import("./configs/pulse/domains/lidl-staging"),
  "apollo-247-pulse.json": () => import("./configs/pulse/domains/apollo-247"),
  "apollo-247-staging-pulse.json": () => import("./configs/pulse/domains/apollo-247-staging"),
  "medibuddy-pulse.json": () => import("./configs/pulse/domains/medibuddy"),
  "medibuddy-staging-pulse.json": () => import("./configs/pulse/domains/medibuddy-staging"),
  "homepro-pulse.json": () => import("./configs/pulse/domains/homepro"),
  "homepro-staging-pulse.json": () => import("./configs/pulse/domains/homepro-staging"),
  "retailer-pulse.json": () => import("./configs/pulse/domains/retailer"),
  "retailer-staging-pulse.json": () => import("./configs/pulse/domains/retailer-staging"),
  "tata-digital-pulse.json": () => import("./configs/pulse/domains/tata-digital"),
  "tata-digital-staging-pulse.json": () => import("./configs/pulse/domains/tata-digital-staging"),
  "dunnhumby-sandbox-pulse.json": () => import("./configs/pulse/domains/dunnhumby-sandbox-pulse"),
};

export const getDomainConfiguration = ({ clientConfigMapping = {} }) => {
  const domain = window.location.hostname;
  const params = new URLSearchParams(window.location.search);
  let configName = params.has("configName") ? params.get("configName") : null;
  const domainParts = domain.split(".onlinesales.ai");

  if (isStagingDomain && getLSItem("p-configName")) {
    configName = getLSItem("p-configName");
  }

  return getDomainConfig({
    configIdentifier: configName || domainParts[0],
    defaultConfigName: "base.json",
    configOverrides,
    clientConfigMapping,
    configs,
  });
};
