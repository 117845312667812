import appReducer from "@onlinesales-ai/app-v2/application/reducer";
import DomainConfigTypes from "@onlinesales-ai/app-v2/domainConfig/types";
import _isEmpty from "lodash/isEmpty";
import { setCookie, customMergeOS } from "@onlinesales-ai/util-methods-v2";

import Types from "./types";

const initialState = {
  clients: [],
  clientsFetchInProgress: false,
  isAllClientsFetched: false,
  userInfo: null,
  userFetchInProgress: true,
  userFetchError: false,
  activeApp: "ACQUIRE",
  UA_TOKEN: null,
  shopInfo: null,
  isMobile: false,
  fetchUserList: true,
  hasAdminAccess: false,
  fileDownloadStatus: [],
  outlets: [],
  fetchAppLevelData: true,
};

const reducer = (state = initialState, action) => {
  let hasChanged = true;

  switch (action.type) {
    case Types.APP_LOG_OUT:
      {
        state = {
          ...initialState,
          agencySettings: state.agencySettings,
          isMobile: state.isMobile,
          agencyId: state.agencyId,
        };
      }
      break;
    case Types.SET_FETCH_USER_LIST:
      {
        state = {
          ...state,
          fetchUserList: action.isLoading,
        };
      }
      break;
    case Types.SET_HAS_ADMIN_ACCESS:
      {
        state = {
          ...state,
          hasAdminAccess: action.hasAdminAccess,
        };
      }
      break;
    case DomainConfigTypes.SET_DOMAIN_CONFIG:
      {
        const config = action.config || {};
        state = {
          ...state,
          clientIds: [config.marketplaceClientId],
          clientId: config.marketplaceClientId,
        };
        if (_isEmpty(state.shopInfo)) {
          state.shopInfo = {
            currencyCode: config.marketplaceCurrency,
          };
        }

        state = {
          ...state,
          agencySettings: customMergeOS(
            {},
            config?.agencySettings?.default,
            state.agencySettings || {},
            config?.agencySettings?.overrides,
          ),
        };

        setCookie("shop", state.shopInfo, 100);
      }
      hasChanged = false;
      break;
    case Types.SET_APP_LEVEL_DATA_FETCH_STATE:
      {
        state = {
          ...state,
          fetchAppLevelData: action.isLoading,
        };
      }
      break;
    default:
      hasChanged = false;
      break;
  }

  if (!hasChanged) {
    return appReducer(state, action, initialState);
  }

  return state;
};

export default {
  Application: reducer,
};
