import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";

import { AppLoader } from "@onlinesales-ai/app-v2";
import {
  Sentry,
  SentryErrorCatcher,
  sentryErrorBoundryBeforeCapture,
} from "@onlinesales-ai/error-catcher-v2";
import { GlobalSearchProvider } from "@onlinesales-ai/global-search-v2";
import { ShortcutProvider } from "@onlinesales-ai/shortcuts-v2";
import { AccessControllProvider } from "@onlinesales-ai/access-control-v2";
import { BaseClient } from "@onlinesales-ai/services-v2";
import { trackUIPerformance } from "@onlinesales-ai/tracking-reporting-v2";
import { NetworkProvider, themeDetector } from "@onlinesales-ai/util-methods-v2";

import "bootstrap/dist/css/bootstrap.css";
import "@onlinesales-ai/global-css-v2/css/common.less";
import "@onlinesales-ai/global-css-v2/css/fonts.less";
import "@onlinesales-ai/global-css-v2/css/theme.less";
import "@onlinesales-ai/global-css-v2/css/inmar-theme.less";
import "@onlinesales-ai/global-css-v2/css/family-dollar-theme.less";
import "@onlinesales-ai/global-css-v2/css/tops-markets-theme.less";
import "@onlinesales-ai/global-css-v2/css/tving-theme.less";
import "@onlinesales-ai/global-css-v2/css/dark-theme.less";
import "@onlinesales-ai/global-css-v2/css/dunn-humby-theme.less";

import store, { history } from "src/store";
import proxyConfig, { getProxyKey } from "src/cdnProxy";
import InitDomainConfig from "src/domainConfig/InitDomainConfig";
import "src/i18n";

import InitLanguage from "src/i18n/initLanguage";
import demoData from "src/demoData";
import Routes from "./Routes";
import AddOns from "./AddOns";

if (IS_STAGING_BOX) {
  BaseClient.setProxyConfig(proxyConfig, getProxyKey());
}

themeDetector();
const App = () => {
  useEffect(() => {
    BaseClient.proxyMap = demoData;
    try {
      trackUIPerformance();
    } catch (e) {}
  }, []);

  const sentryFallback = (props) => {
    return <SentryErrorCatcher {...props} />;
  };

  return (
    <Sentry.ErrorBoundary fallback={sentryFallback} beforeCapture={sentryErrorBoundryBeforeCapture}>
      <Suspense fallback={AppLoader()}>
        <Provider store={store}>
          <Router history={history}>
            <InitDomainConfig>
              <AccessControllProvider>
                <InitLanguage />
                <ShortcutProvider>
                  <GlobalSearchProvider>
                    <Routes />
                    <NetworkProvider>
                      <AddOns />
                    </NetworkProvider>
                  </GlobalSearchProvider>
                </ShortcutProvider>
              </AccessControllProvider>
            </InitDomainConfig>
          </Router>
        </Provider>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};

export default App;
