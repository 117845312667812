import React, { useContext } from "react";
import { Trans } from "react-i18next";

import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { Text } from "@onlinesales-ai/label-v2";

import "./index.less";

const Pill = ({
  variant,
  position,
  children,
  disabled,
  onClickRemove,
  className,
  hidePillCloseClass,
  listRef,
  size,
  weight,
  textProps,
  clickConfig,
  value,
  ...props
}) => {
  const { redirectUrl } = useContext(
    GlobalContext,
  );

  const onPillClick = () => {
    const pillValue = value || (typeof children === "string" ? children : "");
    switch(clickConfig?.actionType) {
      case "OPEN_HELP_DRAWER": {
        if(clickConfig.helpUrl) {
          PlatformEventManager.emit("OPEN_HELP_DRAWER", { iframeUrl: clickConfig?.helpUrl?.replace("__PILL_NAME_LOWERCASE__", pillValue.toLowerCase().split(" ").join("-")) });
        }
      }
      break;
      case "REDIRECT_URL": {
        if(clickConfig.url) {
          redirectUrl(clickUrl.replace("__PILL_NAME_LOWERCASE__", pillValue.toLowerCase().split(" ").join("-")));
        }
      }
      break;
    }
  };

  return (
    <span
      className={`pill round ${className} ${variant} ${position} ${disabled ? "disabled" : ""} ${weight} ${clickConfig ? "clickable": ""} ${hidePillCloseClass && "hide-pill-close"} ${size}`}
      onClick={clickConfig ? onPillClick : null}
      {...props}
    >
      {typeof children === "string" ? (
        <span className="pill-children">
          <Text showEllipsis {...textProps}>{children}</Text>
        </span>
      ) : (
        <span className="pill-children">{children}</span>
      )}
      {onClickRemove && !hidePillCloseClass ? (
        <i className="remove-pill icon icon-close1" onClick={disabled ? () => {} : onClickRemove} />
      ) : null}
    </span>
  );
};

Pill.List = ({ className, children, listRef }) => {
  return (
    <div className={`pill-list ${className}`} ref={listRef}>
      {children}
    </div>
  );
};

Pill.defaultProps = {
  variant: "darkblue", // darkblue,grey,success,danger,white
  className: "", // round
  position: "", //left
  size: "",
  weight: "semi-bold",
};

export default Pill;
