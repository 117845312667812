import { subDays } from "date-fns/esm";

import AppActionTypes from "@onlinesales-ai/app-v2/application/types";

import Types from "./types";

const initialState = {
  startDate: subDays(new Date(), "30").valueOf(),
  endDate: subDays(new Date(), "1").valueOf(),
  filters: {},
  compareStartDate: null,
  compareEndDate: null,
  isComparisonEnabled: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.SET_DASHBOARD_DATE_RANGE:
      {
        state = {
          ...state,
          startDate: action.startDate,
          endDate: action.endDate,
          compareStartDate: action.compareStartDate,
          compareEndDate: action.compareEndDate,
          isComparisonEnabled: !!(action.compareStartDate && action.compareEndDate),
        };
      }
      break;
    case Types.SET_DASHBOARD_FILTERS:
      {
        state = {
          ...state,
          filters: action.filters,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  Dashboard: reducer,
};
