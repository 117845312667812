import Types from "./types";

const initialState = {
  data: {},
  isLoading: false,
  fetchError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case Types.SET_SEGMENT_DATA:
      {
        state = {
          ...state,
          data: action.segmentData,
        };
      }
      break;
    case Types.UPDATE_SEGMENT_DATA:
      {
        state = {
          ...state,
          data: {
            ...state.data,
            [action.segmentData.id]: action.segmentData,
          },
        };
      }
      break;
    case Types.SET_SEGMENT_FETCH_STATE:
      {
        state = {
          ...state,
          isLoading: action.isLoading,
          fetchError: action.fetchError,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  Segment: reducer,
};
