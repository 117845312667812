import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderApp } from "@onlinesales-ai/loader-v2";

const DashboardComponents = {
  COMMON: lazy(() => import("./common")),
};

const BaseDashboard = ({ smmDashboardType }) => {
  const Comp = DashboardComponents[smmDashboardType];

  if (!Comp) {
    return <div>Dashboard Loading Error</div>;
  }

  return (
    <div id="dashboard-wrapper-id" className="dashobard-wrapper content-body">
      <Suspense fallback={loaderApp()}>
        <Comp />
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { smmDashboardType } = state.DomainConfig || {};

  return {
    smmDashboardType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseDashboard);
