import React, { useState, useRef, useContext, useCallback, useMemo, useEffect } from "react";
import { Trans } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _debounce from "lodash/debounce";
import _cloneDeep from "lodash/cloneDeep";

import { Drawer } from "@onlinesales-ai/drawer-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { Form } from "@onlinesales-ai/form-components-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { GlobalContext } from "@onlinesales-ai/utils-components-v2";
import { BaseClient, CreativeUploadService } from "@onlinesales-ai/services-v2";
import { MediaMosaicView } from "@onlinesales-ai/media-mosaic-view-v2";
import { fetchPropertySetting as fetchPropertySettingAction } from "@onlinesales-ai/app-v2/application";
import { FetchBanner } from "@onlinesales-ai/message-card-v2";
import { getAgencySettings } from "@onlinesales-ai/util-methods-v2";

import formComponents from "./components";
import CreativeTemplateLibrary from "./creativeTemplateLibrary";
import "./index.less";

export const prepareURLs = (URL, dataToUse) => {
  let givenURL = URL;
  Object.keys(dataToUse).forEach((word) => {
    givenURL = givenURL.replace(
      new RegExp(word, "g"),
      word?.includes("PUBLIC_ID")
        ? encodeURIComponent(dataToUse[word])
        : encodeURIComponent(encodeURIComponent(dataToUse[word])),
    );
  });
  return givenURL;
};

const prepareTemplateConfig = (data, userData = {}) => {
  const { imgTemplates, sampleData } = data || {};
  let dataToUse = sampleData;
  const values = _cloneDeep(userData);
  Object.keys(values).forEach((key) => _isEmpty(values[key]) && delete values[key]);
  if (!_isEmpty(userData)) {
    dataToUse = {
      ...sampleData,
      ...values,
    };
  }

  return imgTemplates.map((imgObj) => {
    const url = prepareURLs(imgObj.URL, dataToUse);
    return {
      ...imgObj,
      ...(imgObj?.config || {}),
      url,
      thumbnailUrl: url,
      type: imgObj?.config?.mediaType,
    };
  });
};

const TemplateSelection = ({
  templates,
  initialValues,
  clientId,
  uploadType,
  osCloudinaryPath,
  fetchPropertySetting,
  jobDoneCallback,
  agencyId,
  uploadTypeByMediaType,
  ...props
}) => {
  const { inputList, sampleData } = templates[0] || {};
  const { showToastMessage } = useContext(GlobalContext);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [creativeDrawer, setCreativeDrawer] = useState({
    isOpen: false,
    creativeConfig: {},
  });
  const [fetchState, setFetchState] = useState({
    isLoading: true,
    fetchError: null,
  });
  const formRef = useRef();

  const [formValuesToSet, setformValuesToSet] = useState({});

  const fetchData = async () => {
    try {
      await fetchPropertySetting({
        propertySettingKey: "DYNAMIC_CREATIVE_TEMPLATE_LIST_CONFIG",
        storeKey: "dynamicCreativeTemplateListConfig",
      });
      setFetchState({
        isLoading: false,
        fetchError: null,
      });
    } catch (err) {
      setFetchState({
        isLoading: false,
        fetchError: err?.errMsg,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onValuesChange = _debounce(
    (values) => {
      setformValuesToSet(values);
    },
    [500],
  );

  const onClickSave = async (values) => {
    const promiseList = [];
    setIsSaveInProgress(true);
    prepareTemplateConfig(creativeDrawer?.creativeConfig, values).map((tmpObj) => {
      if (tmpObj.url) {
        const payload = {
          ...(tmpObj?.config || {}),
          ...(creativeDrawer?.creativeConfig?.config || {}),
          file: tmpObj.url,
          clientId,
          userTags: values.tags || [],
          tags: [
            `CREATED_USING_CREATIVE_TEMPLATE_${agencyId}_${creativeDrawer?.creativeConfig?.id}`,
          ],
          uploadType: uploadType || uploadTypeByMediaType?.[tmpObj?.type],
        };
        promiseList.push(CreativeUploadService.uploadImage(payload, "DYNAMIC_TEMPLATE_CREATION"));
      }
    });
    Promise.all(promiseList)
      .then(() => {
        setIsSaveInProgress(false);
        jobDoneCallback();
        setCreativeDrawer({ isOpen: false, creativeConfig: {} });
        showToastMessage({
          type: "SUCCESS",
          messageToDisplay: "Creatives are created successfully and added in creative library",
          actionButtonLabel: null,
          toastDuration: 5000,
        });
      })
      .catch(() => {
        setIsSaveInProgress(false);
        showToastMessage({
          type: "ERROR",
          messageToDisplay: BaseClient.getErrorMessage(),
          actionButtonLabel: null,
          toastDuration: 5000,
        });
      });
  };

  const onSaveCreateTemplate = async () => {
    try {
      await formRef.current.submitForm();
    } catch (e) {}
  };

  const getMediaDataDOM = ({ height, width, sizeInBytes }) => {
    return (
      <div className="media-data-dom">
        {!!width && !!height && <span>{`${width} x ${height}`}</span>}
      </div>
    );
  };

  const renderOverlayDomNode = (value, formProp = {}) => {
    let valueToUse = value;
    if (formProp.pickDataFromKey === "publicId") {
      // in case of publicId as value appending onlinesales cloudinary url
      valueToUse = `${osCloudinaryPath}${value}`;
    }
    return <AsyncImage imgSrc={valueToUse} />;
  };

  return (
    <FetchBanner
      errorTitle="Error while fetching templates"
      isLoading={fetchState.isLoading}
      errorMsg={fetchState.fetchError}
    >
      <div>
        <CreativeTemplateLibrary
          prepareURLs={prepareURLs}
          data={templates}
          selectTemplate={(data) => {
            setCreativeDrawer({ isOpen: true, creativeConfig: data });
          }}
        />
        <Drawer
          isOpen={creativeDrawer?.isOpen}
          onClickClose={() => {
            setCreativeDrawer({ isOpen: false, creativeConfig: {} });
            setformValuesToSet({});
            formRef.current.resetForm();
          }}
          closebutton
          header="Create Creative"
          size="large"
          containerClass="template-selection-drawer"
        >
          <Drawer.Body>
            <div className="template-selection-drawer-inner">
              <Form
                componentConfig={creativeDrawer?.creativeConfig?.inputList?.componentConfig}
                componentToShow={creativeDrawer?.creativeConfig?.inputList?.componentToShow}
                formComponents={formComponents}
                onSubmit={onClickSave}
                formRef={formRef}
                onFieldChange={onValuesChange}
                renderCTA={() => {}}
                childComponentProps={{
                  enableTemplateSelection: false,
                  shouldShowDirectUploadBtn: true,
                  renderOverlayDomNode,
                  uploadFile: {
                    uploadType: "CLOUDINARY",
                  },
                  directUploadFileProps: {
                    accept: "image/*",
                  },
                }}
              />
              <div className="template-preview-section">
                <div className="title">
                  <Trans>Previews:</Trans>{" "}
                </div>
                <div className="template-preview-section-inner">
                  {creativeDrawer?.creativeConfig?.imgTemplates && (
                    <MediaMosaicView
                      columnWidth={creativeDrawer?.creativeConfig?.imgTemplates?.length === 1 ? "100%" : "50%"}
                      mediaDataDOM={getMediaDataDOM}
                      mediaList={prepareTemplateConfig(
                        creativeDrawer?.creativeConfig,
                        formValuesToSet,
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </Drawer.Body>
          <Drawer.Footer>
            <Button
              onClick={onSaveCreateTemplate}
              isLoading={isSaveInProgress}
              disabled={isSaveInProgress}
              className="pendo-track-event-creative-creation-via-template-save-click"
            >
              Save
            </Button>
          </Drawer.Footer>
        </Drawer>
      </div>
    </FetchBanner>
  );
};

TemplateSelection.defaultProps = {
  osCloudinaryPath: "https://res.cloudinary.com/onlinesales/image/upload/",
  // templates: [
  //   {
  //     id: 1,
  //     name: "Product Banner Template",
  //     tags: ["Banner", "PDA", "Horizontal"],
  //     isSelected: false,
  //     imgTemplates: [
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/h_130,w_850,co_rgb:ffffff,l_text:montserrat_38_semibold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_130,w_850/fl_layer_apply,g_north,y_120,x_225/h_100,w_850,co_rgb:ffffff,l_text:montserrat_24_light_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_100,w_850/fl_layer_apply,g_north,y_225,x_225/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_285,w_285/fl_layer_apply,g_west,x_30,y_15/l___LOGO_PUBLIC_ID__/e_make_transparent:10/c_fit,h_100,w_100/fl_layer_apply,g_north_east,x_5,y_5/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_323,w_1080/c_fit,h_50,w_167/fl_layer_apply,g_north,x_-125,y_325/h_30,w_150,co_rgb:000000,l_text:montserrat_23_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_150/fl_layer_apply,g_north,y_334,x_-125/Target_Div/HomePage_Multi_Banner_1440x450/Homepage_Multi__Banner.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "1440",
  //           height: "450",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/h_130,w_350,co_rgb:ffffff,l_text:montserrat_33_semibold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_130,w_350/fl_layer_apply,g_north,y_120,x_200/h_130,w_350,co_rgb:ffffff,l_text:montserrat_23_light_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_130,w_350/fl_layer_apply,g_north,y_250,x_200/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_245,w_245/fl_layer_apply,g_west,x_10,y_-5/l___LOGO_PUBLIC_ID__/e_make_transparent:10/c_fit,h_75,w_75/fl_layer_apply,g_north_east,x_5,y_5/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_350,w_1080/c_fit,h_49,w_151/fl_layer_apply,g_north,x_90,y_370/h_30,w_149,co_rgb:000000,l_text:montserrat_23_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_149/fl_layer_apply,g_north,y_380,x_90/Target_Div/800x500-Desktop/800x500_Desktop_6.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "800",
  //           height: "500",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/h_100,w_600,co_rgb:ffffff,l_text:montserrat_35_semibold_center:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_100,w_600/fl_layer_apply,g_north,y_465/h_80,w_600,co_rgb:ffffff,l_text:montserrat_26_light_center:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_80,w_600/fl_layer_apply,g_north,y_550/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_295,w_295/fl_layer_apply,g_north_west,x_16,y_110/l___LOGO_PUBLIC_ID__/e_make_transparent:10/c_fit,h_100,w_100/fl_layer_apply,g_north_east,x_15,y_15/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_323,w_1080/c_fit,h_50,w_167/fl_layer_apply,g_north,y_625/h_30,w_149,co_rgb:000000,l_text:montserrat_23_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_149/fl_layer_apply,g_north,y_635/Target_Div/668x731/668x731_7.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "668",
  //           height: "731",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/h_100,w_325,co_rgb:ffffff,l_text:montserrat_22_semibold_center:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_100,w_325/fl_layer_apply,g_north,y_325/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_340,w_1080/c_fit,h_44,w_150/fl_layer_apply,g_south,y_45/h_25,w_120,co_rgb:000000,l_text:montserrat_22_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_25,w_120/fl_layer_apply,g_south,y_55/l___LOGO_PUBLIC_ID__/e_make_transparent:10/c_fit,h_80,w_80/fl_layer_apply,g_north_east,x_10,y_10/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_160,w_160/fl_layer_apply,g_west,x_10,y_-65/Target_Div/374x500_Mobile/374x500_Mobil.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "374",
  //           height: "500",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/h_130,w_850,co_rgb:ffffff,l_text:montserrat_38_semibold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_130,w_850/fl_layer_apply,g_north,y_90,x_220/h_80,w_850,co_rgb:ffffff,l_text:montserrat_24_light_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_80,w_850/fl_layer_apply,g_north,y_185,x_220/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_265,w_265/fl_layer_apply,g_west,x_30/l___LOGO_PUBLIC_ID__/e_make_transparent:10/c_fit,h_80,w_80/fl_layer_apply,g_north_east,x_5,y_5/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_323,w_1080/c_fit,h_50,w_167/fl_layer_apply,g_center,x_-125,y_105/h_30,w_150,co_rgb:000000,l_text:montserrat_23_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_150/fl_layer_apply,g_center,y_105,x_-125/Target_Div/Banner_1440x350/Banner_1440x350_4.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "1440",
  //           height: "350",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/h_30,w_200,co_rgb:ffffff,l_text:montserrat_11_semibold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_30,w_200/fl_layer_apply,g_north,y_19,x_50/h_25,w_215,co_rgb:ffffff,l_text:montserrat_9_light_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_25,w_215/fl_layer_apply,g_north,y_49,x_56/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_75,w_75/fl_layer_apply,g_west,x_6/l___LOGO_PUBLIC_ID__/e_make_transparent:10/c_fit,h_30,w_30/fl_layer_apply,g_north_east,x_1,y_2/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_329,w_1080/c_fit,h_18,w_58/fl_layer_apply,g_center,x_-23,y_34/h_20,w_50,co_rgb:000000,l_text:montserrat_9_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_20,w_50/fl_layer_apply,g_center,y_38,x_-23/Target_Div/Multi_Banner_Mobile_376x98/Multi_Banner_Mobile_376x98_2.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "376",
  //           height: "98",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //     ],
  //     sampleData: {
  //       __TITLE__: "Lenovo IdeaPad 3i Laptop",
  //       __DESCRIPTION__: "Sleek smart powerful and portable laptop designed to enhance daily sessions",
  //       __CTA_TEXT__: "Order Now",
  //       __LOGO_PUBLIC_ID__: "Target_Div:testing:lenovo_logo",
  //       __PRODUCT_IMAGE_PUBLIC_ID__: "laptop_1_wpqc0t",
  //     },
  //     inputList: {
  //       componentToShow: [
  //         "__TITLE__",
  //         "__DESCRIPTION__",
  //         "__CTA_TEXT__",
  //         "__LOGO_PUBLIC_ID__",
  //         "__PRODUCT_IMAGE_PUBLIC_ID__",
  //         "TAGS",
  //       ],
  //       componentConfig: {
  //         __TITLE__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Headline 1",
  //             dataKey: "__TITLE__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter headline 1",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter headline 1",
  //               },
  //             ],
  //           },
  //         },
  //         __DESCRIPTION__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Description",
  //             dataKey: "__DESCRIPTION__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter description",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter description",
  //               },
  //             ],
  //           },
  //         },
  //         __CTA_TEXT__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "CTA Text",
  //             dataKey: "__CTA_TEXT__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter CTA text",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter CTA text",
  //               },
  //             ],
  //           },
  //         },
  //         __LOGO_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Logo",
  //             overlayDomText: "Upload Logo",
  //             dataKey: "__LOGO_PUBLIC_ID__",
  //             pickDataFromKey: "publicId",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         __PRODUCT_IMAGE_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Product Image",
  //             overlayDomText: "Upload image",
  //             pickDataFromKey: "publicId",
  //             dataKey: "__PRODUCT_IMAGE_PUBLIC_ID__",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         TAGS: {
  //           componentType: "TagInput",
  //           props: {
  //             title: "Tags",
  //             dataKey: "tags",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter tags",
  //             validations: [],
  //           },
  //         },
  //       },
  //     },
  //   },
  //   {
  //     id: 2,
  //     name: "Dynamic Product Banner Template",
  //     tags: ["Dynamic", "Banner", "PDA", "Horizontal"],
  //     isSelected: false,
  //     imgTemplates: [
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/e_replace_color:grey:50:ffffff/e_replace_color:grey:50:000000/c_crop,g_auto,h_100,w_100/b_auto:predominant,c_mpad,g_west,h_450,w_2880/l_Target_Div:HomePage_Multi_Banner_1440x450:Homepage_Multi__Banner/e_screen,fl_layer_apply,g_east/c_crop,g_east,h_450,w_1440/h_130,w_850,co_rgb:ffffff,l_text:montserrat_38_semibold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_130,w_850/fl_layer_apply,g_north,y_120,x_225/h_100,w_850,co_rgb:ffffff,l_text:montserrat_24_light_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_100,w_850/fl_layer_apply,g_north,y_225,x_225/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_285,w_285/fl_layer_apply,g_west,x_30,y_15/l___LOGO_PUBLIC_ID__/c_fit,h_100,w_100/fl_layer_apply,g_north_east,x_5,y_5/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_323,w_1080/c_fit,h_50,w_167/fl_layer_apply,g_north,x_-125,y_325/h_30,w_150,co_rgb:000000,l_text:montserrat_23_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_150/fl_layer_apply,g_north,y_334,x_-125/__PRODUCT_IMAGE_PUBLIC_ID__.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "1440",
  //           height: "450",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/e_replace_color:grey:50:ffffff/e_replace_color:grey:50:000000/c_crop,g_auto,h_100,w_100/b_auto:predominant,c_mpad,g_west,h_500,w_1600/l_Target_Div:800x500-Desktop:800x500_Desktop_6/e_screen,fl_layer_apply,g_east/c_crop,g_east,h_500,w_800//h_130,w_350,co_rgb:ffffff,l_text:montserrat_33_semibold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_130,w_350/fl_layer_apply,g_north,y_120,x_200/h_130,w_350,co_rgb:ffffff,l_text:montserrat_23_light_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_130,w_350/fl_layer_apply,g_north,y_250,x_200/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_245,w_245/fl_layer_apply,g_west,x_10,y_-5/l___LOGO_PUBLIC_ID__/c_fit,h_75,w_75/fl_layer_apply,g_north_east,x_5,y_5/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_350,w_1080/c_fit,h_49,w_151/fl_layer_apply,g_north,x_90,y_370/h_30,w_149,co_rgb:000000,l_text:montserrat_23_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_149/fl_layer_apply,g_north,y_380,x_90/__PRODUCT_IMAGE_PUBLIC_ID__.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "800",
  //           height: "500",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/e_replace_color:grey:50:ffffff/e_replace_color:grey:50:000000/c_crop,g_auto,h_100,w_100/b_auto:predominant,c_mpad,g_west,h_731,w_1336/l_Target_Div:668x731:668x731_7/e_screen,fl_layer_apply,g_east/c_crop,g_east,h_731,w_668/h_100,w_600,co_rgb:ffffff,l_text:montserrat_35_semibold_center:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_100,w_600/fl_layer_apply,g_north,y_465/h_80,w_600,co_rgb:ffffff,l_text:montserrat_26_light_center:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_80,w_600/fl_layer_apply,g_north,y_550/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_295,w_295/fl_layer_apply,g_north_west,x_16,y_110/l___LOGO_PUBLIC_ID__/c_fit,h_100,w_100/fl_layer_apply,g_north_east,x_15,y_15/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_323,w_1080/c_fit,h_50,w_167/fl_layer_apply,g_north,y_625/h_30,w_149,co_rgb:000000,l_text:montserrat_23_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_149/fl_layer_apply,g_north,y_635/__PRODUCT_IMAGE_PUBLIC_ID__.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "668",
  //           height: "731",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/e_replace_color:grey:50:ffffff/e_replace_color:grey:50:000000/c_crop,g_auto,h_100,w_100/b_auto:predominant,c_mpad,g_west,h_500,w_748/l_Target_Div:374x500_Mobile:374x500_Mobil/e_screen,fl_layer_apply,g_east/c_crop,g_east,h_500,w_374/h_100,w_325,co_rgb:ffffff,l_text:montserrat_23_semibold_center:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_100,w_325/fl_layer_apply,g_north,y_325/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_340,w_1080/c_fit,h_44,w_150/fl_layer_apply,g_south,y_45/h_25,w_120,co_rgb:000000,l_text:montserrat_21_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_25,w_120/fl_layer_apply,g_south,y_55/l___LOGO_PUBLIC_ID__/c_fit,h_80,w_80/fl_layer_apply,g_north_east,x_10,y_10/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_160,w_160/fl_layer_apply,g_west,x_10,y_-65/__PRODUCT_IMAGE_PUBLIC_ID__.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "374",
  //           height: "500",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/e_replace_color:grey:50:ffffff/e_replace_color:grey:50:000000/c_crop,g_auto,h_100,w_100/b_auto:predominant,c_mpad,g_west,h_350,w_2880/l_Target_Div:Banner_1440x350:Banner_1440x350_4/e_screen,fl_layer_apply,g_east/c_crop,g_east,h_350,w_1440/h_130,w_850,co_rgb:ffffff,l_text:montserrat_38_semibold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_130,w_850/fl_layer_apply,g_north,y_90,x_220/h_80,w_850,co_rgb:ffffff,l_text:montserrat_24_light_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_80,w_850/fl_layer_apply,g_north,y_185,x_220/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_265,w_265/fl_layer_apply,g_west,x_30/l___LOGO_PUBLIC_ID__/c_fit,h_80,w_80/fl_layer_apply,g_north_east,x_5,y_5/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_323,w_1080/c_fit,h_50,w_167/fl_layer_apply,g_center,x_-125,y_105/h_30,w_150,co_rgb:000000,l_text:montserrat_23_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_150/fl_layer_apply,g_center,y_105,x_-125/__PRODUCT_IMAGE_PUBLIC_ID__.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "1440",
  //           height: "350",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/e_replace_color:grey:50:ffffff/e_replace_color:grey:50:000000/c_crop,g_auto,h_100,w_100/b_auto:predominant,c_mpad,g_west,h_98,w_752/l_Target_Div:Multi_Banner_Mobile_376x98:Multi_Banner_Mobile_376x98_2/e_screen,fl_layer_apply,g_east/c_crop,g_east,h_98,w_376/h_30,w_200,co_rgb:ffffff,l_text:montserrat_11_semibold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_30,w_200/fl_layer_apply,g_north,y_19,x_50/h_25,w_215,co_rgb:ffffff,l_text:montserrat_9_light_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_25,w_215/fl_layer_apply,g_north,y_49,x_56//l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_75,w_75/fl_layer_apply,g_west,x_6/l___LOGO_PUBLIC_ID__/c_fit,h_30,w_30/fl_layer_apply,g_north_east,x_1,y_2/l_Target_Div:Common-Assets:cta_button_white/c_crop,h_329,w_1080/c_fit,h_18,w_58/fl_layer_apply,g_center,x_-23,y_34/h_20,w_50,co_rgb:000000,l_text:montserrat_9_normal_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_20,w_50/fl_layer_apply,g_center,y_38,x_-23/__PRODUCT_IMAGE_PUBLIC_ID__.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "376",
  //           height: "98",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //     ],
  //     sampleData: {
  //       __TITLE__: "Reebok Walk Ultra 7 DMX Men's Shoes Sneakers",
  //       __DESCRIPTION__: "Men's air-cushioned shoes with foam cushioning",
  //       __CTA_TEXT__: "Order Now",
  //       __LOGO_PUBLIC_ID__: "Target_Div:Common-Assets:reebok-logo",
  //       __PRODUCT_IMAGE_PUBLIC_ID__: "reebok_shoe_blue_kdtp5t",
  //     },
  //     inputList: {
  //       componentToShow: [
  //         "__TITLE__",
  //         "__DESCRIPTION__",
  //         "__CTA_TEXT__",
  //         "__LOGO_PUBLIC_ID__",
  //         "__PRODUCT_IMAGE_PUBLIC_ID__",
  //         "TAGS",
  //       ],
  //       componentConfig: {
  //         __TITLE__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Headline 1",
  //             dataKey: "__TITLE__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter headline 1",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter headline 1",
  //               },
  //             ],
  //           },
  //         },
  //         __DESCRIPTION__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Description",
  //             dataKey: "__DESCRIPTION__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter description",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter description",
  //               },
  //             ],
  //           },
  //         },
  //         __CTA_TEXT__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "CTA Text",
  //             dataKey: "__CTA_TEXT__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter headline 2",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter headline 2",
  //               },
  //             ],
  //           },
  //         },
  //         __LOGO_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Logo",
  //             overlayDomText: "Upload Logo",
  //             dataKey: "__LOGO_PUBLIC_ID__",
  //             pickDataFromKey: "publicId",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         __PRODUCT_IMAGE_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Product Image",
  //             overlayDomText: "Upload image",
  //             pickDataFromKey: "publicId",
  //             dataKey: "__PRODUCT_IMAGE_PUBLIC_ID__",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         TAGS: {
  //           componentType: "TagInput",
  //           props: {
  //             title: "Tags",
  //             dataKey: "tags",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter tags",
  //             validations: [],
  //           },
  //         },
  //       },
  //     },
  //   },
  //   {
  //     id: 3,
  //     name: "Body Shop Banner Template",
  //     tags: ["Banner", "Horizontal"],
  //     isSelected: false,
  //     imgTemplates: [
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_349,w_349/fl_layer_apply,g_west,x_165/l___LOGO_PUBLIC_ID__/c_fit,h_80,w_80/e_make_transparent:10/fl_layer_apply,g_west,x_80/h_80,w_600,co_rgb:ffffff,l_text:Open%20Sans_35_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_80,w_600/fl_layer_apply,g_north,y_60,x_190/h_80,w_580,co_rgb:ffffff,l_text:Open%20Sans_30_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_80,w_580/fl_layer_apply,g_north,y_119,x_180/l_cta_button_lukn71/fl_layer_apply,g_south_east,x_35,y_35/h_40,w_170,co_rgb:ffffff,l_text:Open%20Sans_25_semibold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_40,w_170/fl_layer_apply,g_south_east,x_30,y_43/1200x350_bg_dv1x8f.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "1200",
  //           height: "350",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_189,w_189/fl_layer_apply,g_west,x_75/l___LOGO_PUBLIC_ID__/c_fit,h_59,w_59/e_make_transparent:10/fl_layer_apply,g_north_east,x_20,y_18/h_60,w_500,co_rgb:38534b,l_text:Open%20Sans_30_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_60,w_500/fl_layer_apply,g_north,y_45,x_163/h_70,w_470,co_rgb:38534b,l_text:Open%20Sans_22_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_70,w_470/fl_layer_apply,g_north,y_95,x_148/h_40,w_170,co_rgb:ffffff,l_text:Open%20Sans_16_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_40,w_170/fl_layer_apply,g_south,x_-33,y_25/970x250_bg_sermyg.jpg",
  //         altText: "Horizontal Banner",
  //         config: {
  //           width: "970",
  //           height: "250",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //     ],
  //     sampleData: {
  //       __TITLE__: "BRITISH ROSE SHOWER GEL",
  //       __DESCRIPTION__: "Awaken Your Skin With Irresitbly Dewy Scent Of Roses",
  //       __CTA_TEXT__: "BUY NOW",
  //       __LOGO_PUBLIC_ID__: "logo_q98or9",
  //       __PRODUCT_IMAGE_PUBLIC_ID__: "Product_image_w2fvx4",
  //     },
  //     inputList: {
  //       componentToShow: [
  //         "__TITLE__",
  //         "__DESCRIPTION__",
  //         "__CTA_TEXT__",
  //         "__LOGO_PUBLIC_ID__",
  //         "__PRODUCT_IMAGE_PUBLIC_ID__",
  //         "TAGS",
  //       ],
  //       componentConfig: {
  //         __TITLE__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Headline 1",
  //             dataKey: "__TITLE__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter headline 1",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter headline 1",
  //               },
  //             ],
  //           },
  //         },
  //         __DESCRIPTION__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Description",
  //             dataKey: "__DESCRIPTION__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter description",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter description",
  //               },
  //             ],
  //           },
  //         },
  //         __CTA_TEXT__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "CTA Text",
  //             dataKey: "__CTA_TEXT__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter CTA text",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter CTA text",
  //               },
  //             ],
  //           },
  //         },
  //         __LOGO_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Logo",
  //             overlayDomText: "Upload Logo",
  //             dataKey: "__LOGO_PUBLIC_ID__",
  //             pickDataFromKey: "publicId",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         __PRODUCT_IMAGE_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Product Image",
  //             overlayDomText: "Upload image",
  //             pickDataFromKey: "publicId",
  //             dataKey: "__PRODUCT_IMAGE_PUBLIC_ID__",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         TAGS: {
  //           componentType: "TagInput",
  //           props: {
  //             title: "Tags",
  //             dataKey: "tags",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter tags",
  //             validations: [],
  //           },
  //         },
  //       },
  //     },
  //   },
  //   {
  //     id: 4,
  //     name: "Paper Mate Banner Template",
  //     tags: ["Banner", "Horizontal"],
  //     isSelected: false,
  //     imgTemplates: [
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_260,w_225/fl_layer_apply,g_east,x_40/l___LOGO_PUBLIC_ID__/c_fit,h_31,w_141/e_make_transparent:10/fl_layer_apply,g_north_west,x_45,y_30/h_40,w_170,co_rgb:4494d3,l_text:Open%20Sans_18_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_40,w_170/fl_layer_apply,g_south,x_-214,y_23/h_60,w_600,co_rgb:FFFFFF,l_text:Open%20Sans_40_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_60,w_600/fl_layer_apply,g_west,x_25,y_-10/h_60,w_600,co_rgb:FFFFFF,l_text:Open%20Sans_30_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_60,w_600/fl_layer_apply,g_west,x_25,y_52/1200x350_bg_fpgbhz.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "1200",
  //           height: "350",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_172,w_172/fl_layer_apply,g_east,x_35/l___LOGO_PUBLIC_ID__/c_fit,h_23,w_103/e_make_transparent:10/fl_layer_apply,g_north_west,x_45,y_20/h_40,w_170,co_rgb:4494d3,l_text:Open%20Sans_13_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_40,w_170/fl_layer_apply,g_south,x_-204,y_7/h_50,w_600,co_rgb:FFFFFF,l_text:Open%20Sans_28_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_50,w_600/fl_layer_apply,g_west,x_30/h_50,w_600,co_rgb:FFFFFF,l_text:Open%20Sans_20_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_50,w_600/fl_layer_apply,g_west,x_30,y_50/970x250_bg_dvq0vl.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "970",
  //           height: "250",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_138,w_118/fl_layer_apply,g_east,x_10,y_-10/l___LOGO_PUBLIC_ID__/c_fit,h_14,w_59/e_make_transparent:10/fl_layer_apply,g_north_west,x_15,y_16/h_30,w_100,co_rgb:4494d3,l_text:Open%20Sans_12_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_100/fl_layer_apply,g_south_east,x_12,y_4/h_60,w_170,co_rgb:FFFFFF,l_text:Open%20Sans_18_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_60,w_170/fl_layer_apply,g_south_west,x_7,y_65/h_40,w_160,co_rgb:FFFFFF,l_text:Open%20Sans_12_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_40,w_160/fl_layer_apply,g_south_west,x_7,y_30/300x300_bg_uawkdm.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "300",
  //           height: "300",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_188,w_162/fl_layer_apply,g_east,x_45/l___LOGO_PUBLIC_ID__/c_fit,h_22,w_100/e_make_transparent:10/fl_layer_apply,g_north_west,x_60,y_25/h_30,w_100,co_rgb:4494d3,l_text:Open%20Sans_12_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_100/fl_layer_apply,g_south,x_-45,y_20/h_70,w_250,co_rgb:FFFFFF,l_text:Open%20Sans_25_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_70,w_250/fl_layer_apply,g_west,x_15,y_-5/h_70,w_250,co_rgb:FFFFFF,l_text:Open%20Sans_18_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_70,w_250/fl_layer_apply,g_west,x_15,y_67/640x320_bg_pphenp.jpg",
  //         altText: "Modal",
  //         config: {
  //           width: "640",
  //           height: "320",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_126,w_109/fl_layer_apply,g_east,x_18/l___LOGO_PUBLIC_ID__/c_fit,h_26,w_116/e_make_transparent:10/fl_layer_apply,g_north_west,x_40,y_20/h_30,w_140,co_rgb:4494d3,l_text:Open%20Sans_20_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_140/fl_layer_apply,g_center,x_205/h_40,w_450,co_rgb:FFFFFF,l_text:Open%20Sans_30_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_40,w_450/fl_layer_apply,g_center,x_-160,y_-20/h_30,w_450,co_rgb:FFFFFF,l_text:Open%20Sans_22_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_30,w_450/fl_layer_apply,g_center,x_-160,y_25/1200x175_bg_bn4znd.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "1200",
  //           height: "175",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //     ],
  //     sampleData: {
  //       __TITLE__: "PAPER MATE INKJOY GEL PENS",
  //       __DESCRIPTION__: "Enjoy a smooth gel ink that dries 3x faster",
  //       __CTA_TEXT__: "BUY NOW",
  //       __LOGO_PUBLIC_ID__: "brand-logo_adbwwr",
  //       __PRODUCT_IMAGE_PUBLIC_ID__: "product_ehrkka",
  //     },
  //     inputList: {
  //       componentToShow: [
  //         "__TITLE__",
  //         "__DESCRIPTION__",
  //         "__CTA_TEXT__",
  //         "__LOGO_PUBLIC_ID__",
  //         "__PRODUCT_IMAGE_PUBLIC_ID__",
  //         "TAGS",
  //       ],
  //       componentConfig: {
  //         __TITLE__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Headline 1",
  //             dataKey: "__TITLE__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter headline 1",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter headline 1",
  //               },
  //             ],
  //           },
  //         },
  //         __DESCRIPTION__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Description",
  //             dataKey: "__DESCRIPTION__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter description",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter description",
  //               },
  //             ],
  //           },
  //         },
  //         __CTA_TEXT__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "CTA Text",
  //             dataKey: "__CTA_TEXT__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter CTA text",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter CTA text",
  //               },
  //             ],
  //           },
  //         },
  //         __LOGO_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Logo",
  //             overlayDomText: "Upload Logo",
  //             dataKey: "__LOGO_PUBLIC_ID__",
  //             pickDataFromKey: "publicId",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         __PRODUCT_IMAGE_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Product Image",
  //             overlayDomText: "Upload image",
  //             pickDataFromKey: "publicId",
  //             dataKey: "__PRODUCT_IMAGE_PUBLIC_ID__",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         TAGS: {
  //           componentType: "TagInput",
  //           props: {
  //             title: "Tags",
  //             dataKey: "tags",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter tags",
  //             validations: [],
  //           },
  //         },
  //       },
  //     },
  //   },
  //   {
  //     id: 5,
  //     name: "Revlon Product Banner",
  //     tags: ["Banner", "Horizontal"],
  //     isSelected: false,
  //     imgTemplates: [
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_200,w_200/fl_layer_apply,g_north,x_235,y_24/l___LOGO_PUBLIC_ID__/c_fit,h_23,w_103/fl_layer_apply,g_north_west,x_9,y_20/h_40,w_170,co_rgb:ffffff,l_text:Open%20Sans_13_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_40,w_170/fl_layer_apply,g_south,x_-372,y_27/h_50,w_540,co_rgb:53253f,l_text:Open%20Sans_28_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_50,w_540/fl_layer_apply,g_west,x_60,y_-10/h_50,w_520,co_rgb:53253f,l_text:Open%20Sans_20_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_50,w_520/fl_layer_apply,g_west,x_60,y_35/970x250_3_djhgd0.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "970",
  //           height: "250",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_210,w_210/fl_layer_apply,g_north,x_180,y_70/l___LOGO_PUBLIC_ID__/c_fit,h_47,w_102/e_make_transparent:10/fl_layer_apply,g_west,x_8,y_-140/h_30,w_100,co_rgb:ffffff,l_text:Open%20Sans_15_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_100/fl_layer_apply,g_south,x_-248,y_35/h_70,w_350,co_rgb:53253f,l_text:Open%20Sans_18_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_70,w_350/fl_layer_apply,g_west,x_20,y_50/h_70,w_350,co_rgb:53253F,l_text:Open%20Sans_25_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_70,w_350/fl_layer_apply,g_west,x_20,y_-25/640x320_19_pxpiwz.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "640",
  //           height: "320",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_160,w_160/fl_layer_apply,g_north,y_60,x_70/l___LOGO_PUBLIC_ID__/c_fit,h_20,w_65/fl_layer_apply,g_west,x_26,y_-135/h_25,w_100,co_rgb:ffffff,l_text:Open%20Sans_11_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_25,w_100/fl_layer_apply,g_west,x_4,y_71/h_70,w_120,co_rgb:6b223f,l_text:Open%20Sans_16_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_70,w_120/fl_layer_apply,g_south_west,x_15,y_175/h_70,w_120,co_rgb:6b223f,l_text:Open%20Sans_12_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_70,w_120/fl_layer_apply,g_south_west,x_15,y_103/300x300_-_1_9_s1obaz.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "300",
  //           height: "300",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //     ],
  //     sampleData: {
  //       __TITLE__: "PY NUTRITIVE nourishing Shampoo ",
  //       __DESCRIPTION__: "For dry dehydrated unruly and frizzy hair",
  //       __CTA_TEXT__: "ORDER NOW",
  //       __LOGO_PUBLIC_ID__: "revlon-logo-vector_njhj94",
  //       __PRODUCT_IMAGE_PUBLIC_ID__: "revlon_1_s7udp4",
  //     },
  //     inputList: {
  //       componentToShow: [
  //         "__TITLE__",
  //         "__DESCRIPTION__",
  //         "__CTA_TEXT__",
  //         "__LOGO_PUBLIC_ID__",
  //         "__PRODUCT_IMAGE_PUBLIC_ID__",
  //         "TAGS",
  //       ],
  //       componentConfig: {
  //         __TITLE__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Headline 1",
  //             dataKey: "__TITLE__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter headline 1",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter headline 1",
  //               },
  //             ],
  //           },
  //         },
  //         __DESCRIPTION__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Description",
  //             dataKey: "__DESCRIPTION__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter description",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter description",
  //               },
  //             ],
  //           },
  //         },
  //         __CTA_TEXT__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "CTA Text",
  //             dataKey: "__CTA_TEXT__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter CTA text",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter CTA text",
  //               },
  //             ],
  //           },
  //         },
  //         __LOGO_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Logo",
  //             overlayDomText: "Upload Logo",
  //             dataKey: "__LOGO_PUBLIC_ID__",
  //             pickDataFromKey: "publicId",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         __PRODUCT_IMAGE_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Product Image",
  //             overlayDomText: "Upload image",
  //             pickDataFromKey: "publicId",
  //             dataKey: "__PRODUCT_IMAGE_PUBLIC_ID__",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         TAGS: {
  //           componentType: "TagInput",
  //           props: {
  //             title: "Tags",
  //             dataKey: "tags",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter tags",
  //             validations: [],
  //           },
  //         },
  //       },
  //     },
  //   },
  //   {
  //     id: 6,
  //     name: "Revlon Product Banner 2",
  //     tags: ["Banner", "Horizontal"],
  //     isSelected: false,
  //     imgTemplates: [
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_200,w_200/fl_layer_apply,g_north,x_235,y_24/l___LOGO_PUBLIC_ID__/c_fit,h_23,w_103/e_make_transparent:10/fl_layer_apply,g_north_west,x_5,y_20/h_40,w_170,co_rgb:ffffff,l_text:Open%20Sans_13_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_40,w_170/fl_layer_apply,g_south,x_-372,y_27/h_50,w_540,co_rgb:552e5a,l_text:Open%20Sans_28_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_50,w_540/fl_layer_apply,g_west,x_60,y_-10/h_50,w_520,co_rgb:552e5a,l_text:Open%20Sans_20_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_50,w_520/fl_layer_apply,g_west,x_60,y_35/970x250_2_iitmg5.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "970",
  //           height: "250",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_210,w_210/fl_layer_apply,g_north,x_180,y_70/l___LOGO_PUBLIC_ID__/c_fit,h_47,w_102/e_make_transparent:10/fl_layer_apply,g_west,x_8,y_-140/h_30,w_100,co_rgb:ffffff,l_text:Open%20Sans_15_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_100/fl_layer_apply,g_south,x_-248,y_35/h_70,w_350,co_rgb:552e5a,l_text:Open%20Sans_25_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_70,w_350/fl_layer_apply,g_west,x_20,y_-25/h_70,w_350,co_rgb:53253f,l_text:Open%20Sans_18_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_70,w_350/fl_layer_apply,g_west,x_20,y_50/640x320_20_fqtrux.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "640",
  //           height: "320",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_160,w_160/fl_layer_apply,g_north,y_60,x_70/l___LOGO_PUBLIC_ID__/c_fit,h_20,w_65/fl_layer_apply,g_west,x_26,y_-135/h_25,w_100,co_rgb:8c7db4,l_text:Open%20Sans_11_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_25,w_100/fl_layer_apply,g_west,x_4,y_71/h_70,w_120,co_rgb:ffffff,l_text:Open%20Sans_16_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_70,w_120/fl_layer_apply,g_south_west,x_15,y_175/h_70,w_120,co_rgb:ffffff,l_text:Open%20Sans_12_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_70,w_120/fl_layer_apply,g_south_west,x_15,y_103/300x300_-_1_6_rqtq0q.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "300",
  //           height: "300",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //     ],
  //     sampleData: {
  //       __TITLE__: "Revlon Professional Shampoo",
  //       __DESCRIPTION__: "Sulfate Free Antifading Shampoo for Blondes",
  //       __CTA_TEXT__: "ORDER NOW",
  //       __LOGO_PUBLIC_ID__: "revlon-logo-vector_njhj94",
  //       __PRODUCT_IMAGE_PUBLIC_ID__: "Violet_Revlon_zmgqqk",
  //     },
  //     inputList: {
  //       componentToShow: [
  //         "__TITLE__",
  //         "__DESCRIPTION__",
  //         "__CTA_TEXT__",
  //         "__LOGO_PUBLIC_ID__",
  //         "__PRODUCT_IMAGE_PUBLIC_ID__",
  //         "TAGS",
  //       ],
  //       componentConfig: {
  //         __TITLE__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Headline 1",
  //             dataKey: "__TITLE__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter headline 1",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter headline 1",
  //               },
  //             ],
  //           },
  //         },
  //         __DESCRIPTION__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Description",
  //             dataKey: "__DESCRIPTION__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter description",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter description",
  //               },
  //             ],
  //           },
  //         },
  //         __CTA_TEXT__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "CTA Text",
  //             dataKey: "__CTA_TEXT__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter CTA text",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter CTA text",
  //               },
  //             ],
  //           },
  //         },
  //         __LOGO_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Logo",
  //             overlayDomText: "Upload Logo",
  //             dataKey: "__LOGO_PUBLIC_ID__",
  //             pickDataFromKey: "publicId",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         __PRODUCT_IMAGE_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Product Image",
  //             overlayDomText: "Upload image",
  //             pickDataFromKey: "publicId",
  //             dataKey: "__PRODUCT_IMAGE_PUBLIC_ID__",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         TAGS: {
  //           componentType: "TagInput",
  //           props: {
  //             title: "Tags",
  //             dataKey: "tags",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter tags",
  //             validations: [],
  //           },
  //         },
  //       },
  //     },
  //   },
  //   {
  //     id: 7,
  //     name: "L'oreal Product Banner",
  //     tags: ["Banner", "Horizontal"],
  //     isSelected: false,
  //     imgTemplates: [
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_204,w_109/fl_layer_apply,g_west,x_70/l___LOGO_PUBLIC_ID__/c_fit,h_27,w_100/e_make_transparent:10/fl_layer_apply,g_north_east,x_40,y_20/h_40,w_600,co_rgb:FFFFFF,l_text:Open%20Sans_27_bold_right:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_40,w_600/fl_layer_apply,g_east,x_30,y_-15/h_30,w_620,co_rgb:FFFFFF,l_text:Open%20Sans_22_right:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_30,w_600/fl_layer_apply,g_east,x_33,y_20/h_40,w_170,co_rgb:8c7db4,l_text:Open%20Sans_15_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_40,w_170/fl_layer_apply,g_south,x_199,y_17/970x250_-01_5_imixvu.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "970",
  //           height: "250",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_254,w_155/fl_layer_apply,g_east,x_35/l___LOGO_PUBLIC_ID__/c_fit,h_47,w_102/e_make_transparent:10/fl_layer_apply,g_north_west,x_57,y_25/h_30,w_100,co_rgb:8c7db4,l_text:Open%20Sans_15_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_100/fl_layer_apply,g_south,x_-45,y_22/h_70,w_320,co_rgb:ffffff,l_text:Open%20Sans_25_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_70,w_320/fl_layer_apply,g_west,x_15,y_-5/h_70,w_250,co_rgb:ffffff,l_text:Open%20Sans_18_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_70,w_250/fl_layer_apply,g_west,x_15,y_67/640x320_10_rl7ben.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "640",
  //           height: "320",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_170,w_102/fl_layer_apply,g_east,x_30/l___LOGO_PUBLIC_ID__/c_fit,h_18,w_62/e_make_transparent:10/fl_layer_apply,g_north_west,x_28,y_13/h_25,w_100,co_rgb:8c7db4,l_text:Open%20Sans_11_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_25,w_100/fl_layer_apply,g_west,x_4,y_87/h_50,w_120,co_rgb:ffffff,l_text:Open%20Sans_16_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_50,w_120/fl_layer_apply,g_south_west,x_15,y_155/h_60,w_120,co_rgb:ffffff,l_text:Open%20Sans_12_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_60,w_120/fl_layer_apply,g_south_west,x_15,y_90/300x300_1_6_eim2x1.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "300",
  //           height: "300",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //     ],
  //     sampleData: {
  //       __TITLE__: "BLONDAGE HIGH BRIGHT SHAMPOO",
  //       __DESCRIPTION__: "For An Instantly Lighter And Brighter Blonde",
  //       __CTA_TEXT__: "ORDER NOW",
  //       __LOGO_PUBLIC_ID__: "Page1_uoybjx",
  //       __PRODUCT_IMAGE_PUBLIC_ID__: "Mask_Group_128758_vugeps",
  //     },
  //     inputList: {
  //       componentToShow: [
  //         "__TITLE__",
  //         "__DESCRIPTION__",
  //         "__CTA_TEXT__",
  //         "__LOGO_PUBLIC_ID__",
  //         "__PRODUCT_IMAGE_PUBLIC_ID__",
  //         "TAGS",
  //       ],
  //       componentConfig: {
  //         __TITLE__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Headline 1",
  //             dataKey: "__TITLE__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter headline 1",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter headline 1",
  //               },
  //             ],
  //           },
  //         },
  //         __DESCRIPTION__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Description",
  //             dataKey: "__DESCRIPTION__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter description",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter description",
  //               },
  //             ],
  //           },
  //         },
  //         __CTA_TEXT__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "CTA Text",
  //             dataKey: "__CTA_TEXT__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter CTA text",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter CTA text",
  //               },
  //             ],
  //           },
  //         },
  //         __LOGO_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Logo",
  //             overlayDomText: "Upload Logo",
  //             dataKey: "__LOGO_PUBLIC_ID__",
  //             pickDataFromKey: "publicId",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         __PRODUCT_IMAGE_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Product Image",
  //             overlayDomText: "Upload image",
  //             pickDataFromKey: "publicId",
  //             dataKey: "__PRODUCT_IMAGE_PUBLIC_ID__",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         TAGS: {
  //           componentType: "TagInput",
  //           props: {
  //             title: "Tags",
  //             dataKey: "tags",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter tags",
  //             validations: [],
  //           },
  //         },
  //       },
  //     },
  //   },
  //   {
  //     id: 8,
  //     name: "Garnier Product Banner",
  //     tags: ["Banner", "Horizontal"],
  //     isSelected: false,
  //     imgTemplates: [
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_204,w_109/fl_layer_apply,g_west,x_70/l___LOGO_PUBLIC_ID__/c_fit,h_30,w_100/e_make_transparent:10/fl_layer_apply,g_north_east,x_60,y_20/h_50,w_600,co_rgb:FFFFFF,l_text:Open%20Sans_30_bold_right:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_50,w_600/fl_layer_apply,g_east,x_30,y_-15/h_30,w_620,co_rgb:FFFFFF,l_text:Open%20Sans_22_right:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_30,w_600/fl_layer_apply,g_east,x_33,y_20/h_40,w_170,co_rgb:8c7db4,l_text:Open%20Sans_15_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_40,w_170/fl_layer_apply,g_south,x_199,y_17/970x250_01_8_mruir4.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "970",
  //           height: "250",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_254,w_155/fl_layer_apply,g_east,x_35/l___LOGO_PUBLIC_ID__/c_fit,h_47,w_102/e_make_transparent:10/fl_layer_apply,g_north_west,x_57,y_15/h_30,w_100,co_rgb:65a046,l_text:Open%20Sans_15_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_100/fl_layer_apply,g_south,x_-45,y_22/h_70,w_320,co_rgb:FFFFFF,l_text:Open%20Sans_25_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_70,w_320/fl_layer_apply,g_west,x_15,y_-5/h_70,w_250,co_rgb:FFFFFF,l_text:Open%20Sans_18_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_70,w_250/fl_layer_apply,g_west,x_15,y_67/640x320_7_acjdzg.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "640",
  //           height: "320",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_170,w_102/fl_layer_apply,g_east,x_30/l___LOGO_PUBLIC_ID__/c_fit,h_18,w_62/e_make_transparent:10/fl_layer_apply,g_north_west,x_28,y_13/h_25,w_100,co_rgb:65a046,l_text:Open%20Sans_11_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_25,w_100/fl_layer_apply,g_west,x_4,y_87/h_50,w_120,co_rgb:ffffff,l_text:Open%20Sans_16_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_50,w_120/fl_layer_apply,g_south_west,x_15,y_155/h_60,w_120,co_rgb:ffffff,l_text:Open%20Sans_12_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_60,w_120/fl_layer_apply,g_south_west,x_15,y_90/300x300_1_5_ciooui.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "300",
  //           height: "300",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //     ],
  //     sampleData: {
  //       __TITLE__: "FRUCTIS PURE HYDRATING SHAMPOO",
  //       __DESCRIPTION__: "72HR MOISTURE FOR THIRSTY HAIR & DRY SCALP",
  //       __CTA_TEXT__: "ORDER NOW",
  //       __LOGO_PUBLIC_ID__: "5ecab660aa31fc00045d6791_nngbwk",
  //       __PRODUCT_IMAGE_PUBLIC_ID__: "Mask_Group_128760_xdzjct",
  //     },
  //     inputList: {
  //       componentToShow: [
  //         "__TITLE__",
  //         "__DESCRIPTION__",
  //         "__CTA_TEXT__",
  //         "__LOGO_PUBLIC_ID__",
  //         "__PRODUCT_IMAGE_PUBLIC_ID__",
  //         "TAGS",
  //       ],
  //       componentConfig: {
  //         __TITLE__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Headline 1",
  //             dataKey: "__TITLE__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter headline 1",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter headline 1",
  //               },
  //             ],
  //           },
  //         },
  //         __DESCRIPTION__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Description",
  //             dataKey: "__DESCRIPTION__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter description",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter description",
  //               },
  //             ],
  //           },
  //         },
  //         __CTA_TEXT__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "CTA Text",
  //             dataKey: "__CTA_TEXT__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter CTA text",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter CTA text",
  //               },
  //             ],
  //           },
  //         },
  //         __LOGO_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Logo",
  //             overlayDomText: "Upload Logo",
  //             dataKey: "__LOGO_PUBLIC_ID__",
  //             pickDataFromKey: "publicId",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         __PRODUCT_IMAGE_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Product Image",
  //             overlayDomText: "Upload image",
  //             pickDataFromKey: "publicId",
  //             dataKey: "__PRODUCT_IMAGE_PUBLIC_ID__",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         TAGS: {
  //           componentType: "TagInput",
  //           props: {
  //             title: "Tags",
  //             dataKey: "tags",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter tags",
  //             validations: [],
  //           },
  //         },
  //       },
  //     },
  //   },
  //   {
  //     id: 9,
  //     name: "Nexxus Product Banner",
  //     tags: ["Banner", "Horizontal"],
  //     isSelected: false,
  //     imgTemplates: [
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_204,w_109/fl_layer_apply,g_west,x_70/l___LOGO_PUBLIC_ID__/c_fit,h_30,w_100/e_make_transparent:10/fl_layer_apply,g_north_east,x_40,y_20/h_50,w_600,co_rgb:691f3d,l_text:Open%20Sans_30_bold_right:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_50,w_600/fl_layer_apply,g_east,x_30,y_-15/h_30,w_680,co_rgb:691f3d,l_text:Open%20Sans_22_right:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_30,w_680/fl_layer_apply,g_east,x_33,y_20/h_40,w_170,co_rgb:ffffff,l_text:Open%20Sans_15_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_40,w_170/fl_layer_apply,g_south,x_199,y_17/970x250_01_7_xsix5k.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "970",
  //           height: "250",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_254,w_155/fl_layer_apply,g_east,x_35/l___LOGO_PUBLIC_ID__/c_fit,h_47,w_102/e_make_transparent:10/fl_layer_apply,g_north_west,x_57,y_25/h_30,w_100,co_rgb:ffffff,l_text:Open%20Sans_15_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_30,w_100/fl_layer_apply,g_south,x_-45,y_22/h_70,w_320,co_rgb:691f3d,l_text:Open%20Sans_25_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_70,w_320/fl_layer_apply,g_west,x_15,y_-5/h_70,w_250,co_rgb:691f3d,l_text:Open%20Sans_18_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_70,w_250/fl_layer_apply,g_west,x_15,y_67/640x320_9_tby1no.jpg",
  //         altText: "Banner Ad",
  //         config: {
  //           width: "640",
  //           height: "320",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //       {
  //         URL: "https://res.cloudinary.com/onlinesales/image/upload/l___PRODUCT_IMAGE_PUBLIC_ID__/c_fit,h_170,w_102/fl_layer_apply,g_east,x_30/l___LOGO_PUBLIC_ID__/c_fit,h_16,w_58/e_make_transparent:10/fl_layer_apply,g_north_west,x_18,y_15/h_25,w_100,co_rgb:ffffff,l_text:Open%20Sans_11_bold_center:__CTA_TEXT__,c_fit,fl_text_no_trim/c_scale,h_25,w_100/fl_layer_apply,g_west,x_4,y_87/h_50,w_120,co_rgb:681f3c,l_text:Open%20Sans_16_bold_left:__TITLE__,c_fit,fl_text_no_trim/c_scale,h_50,w_120/fl_layer_apply,g_south_west,x_15,y_155/h_60,w_120,co_rgb:681f3c,l_text:Open%20Sans_12_left:__DESCRIPTION__,c_fit,fl_text_no_trim/c_scale,h_60,w_120/fl_layer_apply,g_south_west,x_15,y_90/300x300_1_2_fjbdkc.jpg",
  //         altText: "Display Ad",
  //         config: {
  //           width: "300",
  //           height: "300",
  //           mediaType: "IMAGE",
  //         },
  //       },
  //     ],
  //     sampleData: {
  //       __TITLE__: "COLOR ASSURE SHAMPOO",
  //       __DESCRIPTION__: "UNLEASH VIBRANT COLOR POWERED BY SCIENCE AND NATURE",
  //       __CTA_TEXT__: "ORDER NOW",
  //       __LOGO_PUBLIC_ID__: "Mask_Group_128762_dugape",
  //       __PRODUCT_IMAGE_PUBLIC_ID__: "Mask_Group_128759_msv6bx",
  //     },
  //     inputList: {
  //       componentToShow: [
  //         "__TITLE__",
  //         "__DESCRIPTION__",
  //         "__CTA_TEXT__",
  //         "__LOGO_PUBLIC_ID__",
  //         "__PRODUCT_IMAGE_PUBLIC_ID__",
  //         "TAGS",
  //       ],
  //       componentConfig: {
  //         __TITLE__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Headline 1",
  //             dataKey: "__TITLE__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter headline 1",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter headline 1",
  //               },
  //             ],
  //           },
  //         },
  //         __DESCRIPTION__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "Description",
  //             dataKey: "__DESCRIPTION__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter description",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter description",
  //               },
  //             ],
  //           },
  //         },
  //         __CTA_TEXT__: {
  //           componentType: "InputText",
  //           props: {
  //             title: "CTA Text",
  //             dataKey: "__CTA_TEXT__",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter CTA text",
  //             validations: [
  //               {
  //                 type: "nonEmpty",
  //                 msg: "Please enter CTA text",
  //               },
  //             ],
  //           },
  //         },
  //         __LOGO_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Logo",
  //             overlayDomText: "Upload Logo",
  //             dataKey: "__LOGO_PUBLIC_ID__",
  //             pickDataFromKey: "publicId",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         __PRODUCT_IMAGE_PUBLIC_ID__: {
  //           componentType: "ImageUploader",
  //           notEditableinEditMode: true,
  //           props: {
  //             title: "Product Image",
  //             overlayDomText: "Upload image",
  //             pickDataFromKey: "publicId",
  //             dataKey: "__PRODUCT_IMAGE_PUBLIC_ID__",
  //             uploadFile: {
  //               uploadType: "CLOUDINARY",
  //             },
  //           },
  //         },
  //         TAGS: {
  //           componentType: "TagInput",
  //           props: {
  //             title: "Tags",
  //             dataKey: "tags",
  //             onlyEmptyValueEdit: false,
  //             fallbackValue: "NA",
  //             placeholder: "Enter tags",
  //             validations: [],
  //           },
  //         },
  //       },
  //     },
  //   },
  // ],
  initialValues: {},
};

const mapStateToProps = (state, ownProps) => {
  const { clientId, dynamicCreativeTemplateListConfig = {}, agencyId } = state.Application || {};
  const { mediaUpload } = (state.DomainConfig?.commonConfigs || {});

  return {
    ...mediaUpload,
    uploadTypeByMediaType: getAgencySettings("uploadTypeByMediaType", state),
    templates: dynamicCreativeTemplateListConfig?.templates || [],
    clientId: ownProps.clientId || clientId,
    agencyId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchPropertySetting: fetchPropertySettingAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSelection);
