// import appReducer from "@onlinesales-ai/app-v2/application/reducer";
import _isEmpty from "lodash/isEmpty";
import _keyBy from "lodash/keyBy";
import _uniq from "lodash/uniq";

import { customMergeOS } from "@onlinesales-ai/util-methods-v2";

import Types from "./types";

const initialState = {
  creatives: {
    creativeDetails: {},
  },
};

const reducer = (state = initialState, action) => {
  let hasChanged = true;

  switch (action.type) {
    case Types.SET_AD_SET_DATA:
      {
        const finalData = customMergeOS({}, state?.creatives?.adSets, action?.adSets);
        state = {
          ...state,
          creatives: {
            ...state?.creatives,
            adSets: finalData,
          },
        };
      }
      break;
    case Types.SET_SORTED_IDS:
      {
        state = {
          ...state,
          creatives: {
            ...state?.creatives,
            sortedIds: action?.ids || [],
          },
        };
      }
      break;
    case Types.UPDATE_SORTED_IDS:
      {
        state = {
          ...state,
          creatives: {
            ...state?.creatives,
            sortedIds: [...(state?.creatives?.sortedIds || []), ...(action?.ids || [])],
          },
        };
      }
      break;
    case Types.SET_AGENCY_ADVERTISER_DATA:
      {
        state = {
          ...state,
          agencyAdvertiserData: {
            ...state?.agencyAdvertiserData,
            entitiesInfo: action?.entitiesInfo,
          },
        };
      }
      break;
    case Types.UPDATE_AGENCY_ADVERTISER_DATA:
      {
        const finalData = customMergeOS(
          {},
          state?.agencyAdvertiserData?.entitiesInfo,
          action?.entitiesInfo,
        );
        state = {
          ...state,
          agencyAdvertiserData: {
            ...state?.agencyAdvertiserData,
            entitiesInfo: finalData,
          },
        };
      }
      break;
    case Types.UPDATE_ENTITY_INFO_COMMENT:
      {
        const info = action?.entityInfo || {};
        const id = `${info?.entityType}_${info?.entityId}`;
        state = {
          ...state,
          agencyAdvertiserData: {
            ...state?.agencyAdvertiserData,
            entitiesInfo: {
              ...state?.agencyAdvertiserData?.entitiesInfo,
              [id]: {
                ...(state?.agencyAdvertiserData?.entitiesInfo?.[id] || {}),
                metadata: {
                  ...state?.agencyAdvertiserData?.entitiesInfo?.[id]?.metadata,
                  approvalStatus: info?.approvalStatus,
                },
              },
            },
          },
        };
      }
      break;
    case Types.SET_AGENCY_ADVERTISER_SORTED_IDS:
      {
        state = {
          ...state,
          agencyAdvertiserData: {
            ...state?.agencyAdvertiserData,
            sortedIds: action?.ids || [],
          },
        };
      }
      break;
    case Types.UPDATE_AGENCY_ADVERTISER_SORTED_IDS:
      {
        state = {
          ...state,
          agencyAdvertiserData: {
            ...state?.agencyAdvertiserData,
            sortedIds: _uniq([
              ...(state?.agencyAdvertiserData?.sortedIds || []),
              ...(action?.ids || []),
            ]),
          },
        };
      }
      break;
    case Types.SET_AD_SET_CREATIVE_DATA:
      {
        state = {
          ...state,
          creatives: {
            ...state?.creatives,
            adSets: {
              ...state?.creatives?.adSets,
              [action.adSetId]: {
                ...state?.creatives?.adSets?.[action.adSetId],
                ...action.data,
                creativeIds: (action.data?.creatives || []).map((obj) => obj.id),
              },
            },
            creativeDetails: {
              ...state?.creatives?.creativeDetails,
              ..._keyBy(action.data?.creatives, "id"),
            },
          },
        };
      }
      break;
    case Types.UPDATE_CREATIVE_AD_DATA:
      {
        state = {
          ...state,
          creatives: {
            ...state?.creatives,
            creativeDetails: {
              ...state?.creatives?.creativeDetails,
              [action.creativeId]: {
                ...state?.creatives?.creativeDetails?.[action.creativeId],
                ...action.data,
              },
            },
          },
        };
      }
      break;
    case Types.UPDATE_POSTPAID_BILLING_APPROVALS_DATA:
      {
        const finalData = customMergeOS(
          {},
          state?.ottPostpaidBillingApprovalsData?.approvalData,
          action?.approvalData,
        );
        state = {
          ...state,
          ottPostpaidBillingApprovalsData: {
            ...state?.ottPostpaidBillingApprovalsData,
            approvalData: finalData,
          },
        };
      }
      break;
    case Types.SET_POSTPAID_BILLING_APPROVAL_SORTED_IDS:
      {
        state = {
          ...state,
          ottPostpaidBillingApprovalsData: {
            ...state?.ottPostpaidBillingApprovalsData,
            sortedIds: action.ids || [],
          },
        };
      }
      break;
    case Types.UPDATE_POSTPAID_BILLING_APPROVAL_SORTED_IDS:
      {
        state = {
          ...state,
          ottPostpaidBillingApprovalsData: {
            ...state?.ottPostpaidBillingApprovalsData,
            sortedIds: _uniq([
              ...(state?.ottPostpaidBillingApprovalsData?.sortedIds || []),
              ...(action?.ids || []),
            ]),
          },
        };
      }
      break;
    case Types.UPDATE_POSTPAID_BILLING_APPROVAL_INFO_COMMENT:
      {
        const info = action?.approvalData || {};
        const id = info?.billingProfileId;
        state = {
          ...state,
          ottPostpaidBillingApprovalsData: {
            ...state?.ottPostpaidBillingApprovalsData,
            approvalData: {
              ...state?.ottPostpaidBillingApprovalsData?.approvalData,
              [id]: {
                ...(state?.ottPostpaidBillingApprovalsData?.approvalData?.[id] || {}),
                entityBillingStatus: info?.status,
                billingProfileStatus: info?.status,
              },
            },
          },
        };
      }
      break;
    case Types.UPDATE_POSTPAID_BILLING_APPROVALS_INVOICE_DATA:
      {
        const finalData = customMergeOS(
          {},
          state?.ottPostpaidBillingInvoiceApprovalsData?.approvalData,
          action?.approvalData,
        );
        state = {
          ...state,
          ottPostpaidBillingInvoiceApprovalsData: {
            ...state?.ottPostpaidBillingInvoiceApprovalsData,
            approvalData: finalData,
          },
        };
      }
      break;
    case Types.SET_POSTPAID_BILLING_APPROVAL_INVOICE_SORTED_IDS:
      {
        state = {
          ...state,
          ottPostpaidBillingInvoiceApprovalsData: {
            ...state?.ottPostpaidBillingInvoiceApprovalsData,
            sortedIds: action.ids || [],
          },
        };
      }
      break;
    case Types.UPDATE_POSTPAID_BILLING_APPROVAL_INVOICE_SORTED_IDS:
      {
        state = {
          ...state,
          ottPostpaidBillingInvoiceApprovalsData: {
            ...state?.ottPostpaidBillingInvoiceApprovalsData,
            sortedIds: _uniq([
              ...(state?.ottPostpaidBillingInvoiceApprovalsData?.sortedIds || []),
              ...(action?.ids || []),
            ]),
          },
        };
      }
      break;
    case Types.UPDATE_POSTPAID_BILLING_APPROVAL_INVOICE_INFO_COMMENT:
      {
        const info = action?.approvalData || {};
        const id = info?.customInvoiceId;
        state = {
          ...state,
          ottPostpaidBillingInvoiceApprovalsData: {
            ...state?.ottPostpaidBillingInvoiceApprovalsData,
            approvalData: {
              ...state?.ottPostpaidBillingInvoiceApprovalsData?.approvalData,
              [id]: {
                ...(state?.ottPostpaidBillingInvoiceApprovalsData?.approvalData?.[id] || {}),
                invoiceStatusType: info?.status,
              },
            },
          },
        };
      }
      break;
    default:
      hasChanged = false;
      break;
  }

  // if (!hasChanged) {
  //   return appReducer(state, action, initialState);
  // }

  return state;
};

export default {
  Review: reducer,
};
