import Types from "./types";

const initialState = {
  reports: {},
  isReportsAttributesFetchInProgress: true,
  reportsAttributesFetchErr: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_DASHBOARD_REPORTS_ATTRIBUTES:
      {
        state = {
          ...state,
          reports: action?.reports || [],
        };
      }
      break;
    case Types.SET_DASHBOARD_REPORTS_ATTRIBUTES_FETCH_IN_PROGRESS:
      {
        state = {
          ...state,
          isReportsAttributesFetchInProgress: action?.isFetchInProgress,
        };
      }
      break;
    case Types.SET_DASHBOARD_REPORTS_ATTRIBUTES_FETCH_ERROR:
      {
        state = {
          ...state,
          reportsAttributesFetchErr: action?.error,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  KamDashboard: reducer,
};
