export default {
  BRAND_ADS_FETCH_INPROGRESS: "BRAND_ADS_FETCH_INPROGRESS",
  BRAND_ADS_FETCH_ERROR: "BRAND_ADS_FETCH_ERROR",
  BRAND_ADS_UPDATE_CAMPAIGNS: "BRAND_ADS_UPDATE_CAMPAIGNS",
  BRAND_ADS_UPDATE_CAMPAIGN_STATUS: "BRAND_ADS_UPDATE_CAMPAIGN_STATUS",
  BRAND_ADS_SET_CAMPAIGN_COMMENTS: "BRAND_ADS_SET_CAMPAIGN_COMMENTS",
  BRAND_ADS_ADD_CAMPAIGN_COMMENT: "BRAND_ADS_ADD_CAMPAIGN_COMMENT",
  BRAND_ADS_SET_REVIEWER_EMAIL_LIST: "BRAND_ADS_SET_REVIEWER_EMAIL_LIST",
  BRAND_ADS_SET_IS_REVIEWER_EMAIL_LIST_FETCHED: "BRAND_ADS_SET_IS_REVIEWER_EMAIL_LIST_FETCHED",
  CAMPAIGN_SORTED_IDS: "CAMPAIGN_SORTED_IDS",
  UPDATE_CAMPAIGN_SORTED_IDS: "UPDATE_CAMPAIGN_SORTED_IDS",
  BRAND_ADS_SET_REVIEWER_DETAILS_OPTIONS: "BRAND_ADS_SET_REVIEWER_DETAILS_OPTIONS",
};
