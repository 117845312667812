import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";

import { AppStore } from "@onlinesales-ai/util-methods-v2";
import { smmReducer } from "@onlinesales-ai/smm-common-v2/store";
import { creativesReducer } from "@onlinesales-ai/creatives-v2/store";
import leadV2Reducer from "@onlinesales-ai/lms-v2-new-v2/store/reducer";
import settingReducer from "@onlinesales-ai/app-v2/setting/reducer";
import supportReducer from "@onlinesales-ai/app-v2/support/reducer";
import segmentReducer from "@onlinesales-ai/kam-common-v2/store/segment/reducer";
import reportingReducer from "@onlinesales-ai/reports-v2/store/reporting/reducer";
import financeReducer from "@onlinesales-ai/finance-v2/store/finance/reducer";
import uiNotificationReducer from "@onlinesales-ai/app-v2/uiNotification/reducer";
import omsReducer from "@onlinesales-ai/oms-v2/store/reducer";
import entityApplicationReducer from "@onlinesales-ai/client-v2/store/entityApplication/reducer";

import applicationReducer from "./application/reducer";
import domainConfigReducer from "./domainConfig/reducer";
import dashboardReducer from "./dashboard/reducer";
import masterGoalReducer from "./masterGoals/reducer";
import audienceReducer from "./audience/reducer";
import brandAdsReducer from "./brandAds/reducer";
import kamReducer from "./kam/reducer";
import reviewReducer from "./review/reducer";
import appStoreReducer from "./appStore/reducer";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const rootReducer = combineReducers({
  ...applicationReducer,
  ...domainConfigReducer,
  ...dashboardReducer,
  ...smmReducer,
  ...masterGoalReducer,
  ...creativesReducer,
  ...audienceReducer,
  ...leadV2Reducer,
  ...settingReducer,
  ...brandAdsReducer,
  ...kamReducer,
  ...segmentReducer,
  ...reviewReducer,
  ...reportingReducer,
  ...appStoreReducer,
  ...supportReducer,
  ...financeReducer,
  ...uiNotificationReducer,
  ...omsReducer,
  ...entityApplicationReducer,
  router: routerReducer,
});

const initialState = {};

const middlewares = [thunk, routerMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares)),
);

AppStore.setStore(store);

export const history = createReduxHistory(store);

export default store;
