export default {
  SET_AD_SET_DATA: "SET_AD_SET_DATA",
  SET_SORTED_IDS: "SET_SORTED_IDS",
  UPDATE_SORTED_IDS: "UPDATE_SORTED_IDS",
  SET_AGENCY_ADVERTISER_SORTED_IDS: "SET_AGENCY_ADVERTISER_SORTED_IDS",
  UPDATE_AGENCY_ADVERTISER_SORTED_IDS: "UPDATE_AGENCY_ADVERTISER_SORTED_IDS",
  SET_AGENCY_ADVERTISER_DATA: "SET_AGENCY_ADVERTISER_DATA",
  UPDATE_AGENCY_ADVERTISER_DATA: "UPDATE_AGENCY_ADVERTISER_DATA",
  UPDATE_ENTITY_INFO_COMMENT: "UPDATE_ENTITY_INFO_COMMENT",
  SET_AD_SET_CREATIVE_DATA: "SET_AD_SET_CREATIVE_DATA",
  UPDATE_CREATIVE_AD_DATA: "UPDATE_CREATIVE_AD_DATA",
  UPDATE_POSTPAID_BILLING_APPROVALS_DATA: "UPDATE_POSTPAID_BILLING_APPROVALS_DATA",
  SET_POSTPAID_BILLING_APPROVAL_SORTED_IDS: "SET_POSTPAID_BILLING_APPROVAL_SORTED_IDS",
  UPDATE_POSTPAID_BILLING_APPROVAL_SORTED_IDS: "UPDATE_POSTPAID_BILLING_APPROVAL_SORTED_IDS",
  UPDATE_POSTPAID_BILLING_APPROVAL_INFO_COMMENT: "UPDATE_POSTPAID_BILLING_APPROVAL_INFO_COMMENT",
  UPDATE_POSTPAID_BILLING_APPROVALS_INVOICE_DATA: "UPDATE_POSTPAID_BILLING_APPROVALS_INVOICE_DATA",
  SET_POSTPAID_BILLING_APPROVAL_INVOICE_SORTED_IDS: "SET_POSTPAID_BILLING_APPROVAL_INVOICE_SORTED_IDS",
  UPDATE_POSTPAID_BILLING_APPROVAL_INVOICE_SORTED_IDS: "UPDATE_POSTPAID_BILLING_APPROVAL_INVOICE_SORTED_IDS",
  UPDATE_POSTPAID_BILLING_APPROVAL_INVOICE_INFO_COMMENT: "UPDATE_POSTPAID_BILLING_APPROVAL_INVOICE_INFO_COMMENT",
};
