import React from "react";
import _uniqBy from "lodash/uniqBy";
import _round from "lodash/round";

import { COOKIES } from "@onlinesales-ai/constants-v2";
import { getCurrentLanguage } from "@onlinesales-ai/i18n-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import AppStore from "./appStore";
import { getCookie } from "./cookieHandler";
import { getLSItem, setLSItem } from "./utils";

export const getCurrencyCode = () => {
  let storeState = {};
  try {
    storeState = AppStore.getState();
  } catch (error) {
    uiAPIMonitor("SEV3", "REDUX_RACE_CONDITION", { error });
  }

  const shopObject =
    (storeState.Application?.hasOwnProperty?.("selectedEntityId") ||
    storeState.EntityApplication?.hasOwnProperty?.("selectedEntityId"))
      ? { currencyCode: storeState.DomainConfig.marketplaceCurrency }
      : storeState.Application?.shopInfo || getCookie(COOKIES.SHOP) || {};

  return shopObject.currencyCode || "";
};

const currecyCodeClass = {
  "KRW": {
    default: `currency-icon-krw icon icon-won`,
    ko: `currency-icon-krw icon icon-won-3`,
  },
  "WON": {
    default: `currency-icon-krw icon icon-won`,
    ko: `currency-icon-krw icon icon-won-3`,
  },
};

export const getCurrencyClass = (currencyCode) => {
  currencyCode = currencyCode || getCurrencyCode();

  if (currecyCodeClass[currencyCode]) {
    const language = getCurrentLanguage();

    return (
      currecyCodeClass[currencyCode]?.[language] ||
      currecyCodeClass[currencyCode]?.default ||
      `currency currency-${currencyCode.toLowerCase()}`
    );
  }

  return `currency currency-${currencyCode.toLowerCase()}`;
};

export const getCurrencyIconCode = (currencyCode = "") => {
  if (currecyCodeClass[currencyCode]) {
    const language = getCurrentLanguage();

    return (
      currecyCodeClass[currencyCode]?.[language] ||
      currecyCodeClass[currencyCode]?.default ||
      `currency-icon currency-${currencyCode.toString().toLowerCase()}`
    );
  }

  return `currency-icon currency-${currencyCode.toString().toLowerCase()}`;
};

export const getCurrencyDOM = (currencyCode) => {
  const code = getCurrencyClass(currencyCode);

  return <span className={code} />;
};

export const getNumberFormatIdentifier = () => {
  const storeState = AppStore.getState();

  return storeState.DomainConfig?.numberFormatIdentifier;
};

export const formatNumberRanges = {
  default: [
    { divider: 1000000000, suffix: " B" },
    { divider: 1000000, suffix: " M" },
    { max: 10000, divider: 1000, suffix: " K" },
  ],
  IDR_BILLION: [
    { divider: 1000000000, suffix: " B" }
  ],
  INR: [
    { divider: 10000000, suffix: " Cr" },
    { divider: 100000, suffix: " L" },
    { max: 10000, divider: 1000, suffix: " K" },
  ],
  compact_default: [
    { divider: 1000000000, suffix: " B" },
    { divider: 1000000, suffix: " M" },
    { max: 1000, divider: 1000, suffix: " K" },
  ],
  compact_IDR_BILLION: [
    { divider: 1000000000, suffix: " B" }
  ],
  compact_INR: [
    { divider: 10000000, suffix: " Cr" },
    { divider: 100000, suffix: " L" },
    { max: 1000, divider: 1000, suffix: " K" },
  ],
  KRW: [    
    { divider: 1000000000000, suffix: " 조" }, // Trillion
    { divider: 100000000, suffix: " 억" }, // Hundred Million
    { max: 10000, divider: 10000, suffix: " 만" }, // Ten Thousand
  ],
};

export const currencyToLocaleMapping = {
  INR: {
    locale: "en-IN",
  },
  USD: {
    locale: "en-US",
  },
  THB: {
    locale: "th-TH",
  },
  IDR: {
    // locale: "id-ID",
    locale: "en-US", // avoiding commad dot seperator
  },
  EUR: {
    // locale: "es-ES", // For Spanish (Spain),
    locale: "en-US", // avoiding commad dot seperator
  },
  BDT: {
    locale: "en-BD",
  },
  ZAR: {
    // locale: "en-ZA",
    locale: "en-US", // avoiding commad dot seperator
  },
  PHP: {
    locale: "en-PH",
  },
  VND: {
    // locale: "vi-VN",
    locale: "en-US", // avoiding commad dot seperator
  },
  NGN: {
    locale: "en-NR",
  },
  PKR: {
    locale: "en-PK",
  },
  KRW: {
    locale: "ko-KR",
  }
};

export const formatValuesInThousands = (value, precision = 0) => {
  const currencyCode = getCurrencyCode();
  const locale = currencyToLocaleMapping[currencyCode]?.locale || "en-US";
  let val = parseFloat(value);
  try {
    val = val.toLocaleString(locale, {
      maximumFractionDigits: precision,
      currency: currencyCode,
    });
  } catch(e) {
    if(currencyCode){
      val = val.toLocaleString("en-US", {
        maximumFractionDigits: precision,
        currency: currencyCode,
      });
    }
  }
  return val;
};

export const formatNumberInAbbreviations = (value, precision, formattedValuesPrecision = 1, compactKey) => {
  const currencyCode = getNumberFormatIdentifier() || getCurrencyCode();
  const ranges = compactKey ? formatNumberRanges[`${compactKey}_${currencyCode}`] || formatNumberRanges[`${compactKey}_default`] : formatNumberRanges[currencyCode] || formatNumberRanges.default || [];
  const locale = currencyToLocaleMapping[currencyCode]?.locale || "en-US";

  for (let i = 0; i < ranges.length; i++) {
    const maxValue = ranges[i].max || ranges[i].divider;
    if (value >= maxValue) {
      return (
        Number(value / ranges[i].divider).toLocaleString(
          locale,
          {
            maximumFractionDigits: formattedValuesPrecision,
            // set currency to undefined if currencyCode is "" empty string.
            // Fixed: RangeError: Invalid currency code :
            currency: currencyCode || undefined,
          },
        ) + ranges[i].suffix
      );
    }
  }
  const rtnValue = formatValuesInThousands(value, precision || 0);

  // Fix for case of value(-0.23223..) it was showing -0 as result
  if (Math.abs(rtnValue) === 0) {
    return Math.abs(rtnValue);
  }
  return rtnValue;
};

export const fixedNumber = (number, precision) => {
  return _round(number, precision);
};

export const getEmailsFromLocalStorage = (defaults) => {
  const emailIds = defaults;

  if (emailIds) {
    try {
      emailIds.push(...JSON.parse(getLSItem("sharedEmails")));
    } catch (e) {}
  }

  return _uniqBy(emailIds, "email");
};

export const setEmailsInLocalStorage = (emailIds, defaults) => {
  const emailIdsToAdd = Array.isArray(emailIds) ? emailIds.map((email) => ({ email })) : [];

  try {
    const savedEmailIds = JSON.parse(getLSItem("sharedEmails"));
    if (savedEmailIds?.length) {
      emailIdsToAdd.push(...savedEmailIds);
    }
  } catch (e) {}

  if (defaults?.length) {
    emailIdsToAdd.push(...defaults);
  }

  setLSItem("sharedEmails", JSON.stringify(_uniqBy(emailIdsToAdd, "email")));
};
