import React from "react";
import { connect } from "react-redux";
import "src/i18n";

import { getLSItem } from "@onlinesales-ai/util-methods-v2";
import { changeLanguage } from "@onlinesales-ai/i18n-v2";

class InitLanguage extends React.Component {
  constructor(props) {
    super(props);

    const { search } = props.location || {};
    const searchParams = new URLSearchParams(search);
    let shouldChangeLanguage = !searchParams.get("lng");

    if (shouldChangeLanguage && getLSItem("p-lng")) {
      shouldChangeLanguage = false;
    }

    if (shouldChangeLanguage) {
      if (
        window.location.hostname === "oms-dev-adops.tving.com" ||
        window.location.hostname === "oms-adops.tving.com"
      ) {
        changeLanguage("ko");
      }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
  };
};

export default connect(mapStateToProps)(InitLanguage);
