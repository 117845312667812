import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _pick from "lodash/pick";

import { Button } from "@onlinesales-ai/button-v2";
import { Dropdown } from "@onlinesales-ai/dropdown-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { Text } from "@onlinesales-ai/label-v2";

import { FormWrapper, RuleAction } from "../../index";

import "./index.less";

const Tracker = ({
  fallbackValue,
  showConfirmationModal,
  resetConfirmationModal,
  actionsToShowByTrackerType,
  actionsToShow: pActionsToShow,
  actionConfig: pActionConfig,
  ...props
}) => {
  const {
    isEditable,
    dataKey,
    formValues,
    enableTrackerType,
    trackerTypeProps,
    trackerTypeKey,
    addButtonText,
    onChange,
  } = props;

  const value = useMemo(() => {
    return _get(formValues, dataKey);
  }, [dataKey, formValues]);

  const [trackerType, setTrackerType] = useState(() => value?.[0]?.[trackerTypeKey]);

  const commonInitValueToAdd = useMemo(() => {
    const val = {};

    if (enableTrackerType) {
      val[trackerTypeKey] = trackerType;
    }

    return val;
  }, [trackerType, trackerTypeKey, enableTrackerType]);

  const { actionsToShow, actionConfig } = useMemo(() => {
    let toShow = pActionsToShow;
    let config = pActionConfig;

    if (enableTrackerType && trackerType && actionsToShowByTrackerType) {
      toShow = actionsToShowByTrackerType?.[trackerType] || [];

      config = _pick(config, toShow);

      toShow = toShow.map((key) => config[key]?.keyAlias || key);

      config = Object.keys(config).reduce((memo, key) => {
        return {
          ...memo,
          [config[key]?.keyAlias || key]: config[key],
        };
      }, {});
    }

    return { actionsToShow: toShow, actionConfig: config };
  }, [trackerType, enableTrackerType]);

  useEffect(() => {
    if (!trackerType && value?.[0]?.[trackerTypeKey]) {
      setTrackerType(value?.[0]?.[trackerTypeKey]);
    }
  }, [trackerType, value?.[0]]);

  const customAddButton = ({ onClickAdd }) => {
    if (enableTrackerType && !trackerType) {
      return null;
    }

    return (
      <>
        <Button
          className="add-button pendo_track_feature_ad_tracker_plus_icon_button"
          type="default"
          shape="rounded"
          icon="icon-add-plus"
          iconWeight
          onClick={onClickAdd}
        />
        <Button
          link
          onClick={onClickAdd}
          className="tracker-btn pendo_track_feature_ad_tracker_button"
        >
          {addButtonText}
        </Button>
      </>
    );
  };

  const customLabelFunction = ({ nonEditValue }) => {
    return `${nonEditValue}:`;
  };

  const onChangeTrackerType = (newVal) => {
    if (newVal === trackerType) {
      return null;
    }

    if (value?.length) {
      showConfirmationModal({
        isShow: true,
        title: trackerTypeProps?.confirmationMessage,
        rightBtnText: "No",
        actionBtnText: "Yes",
        actionBtnCallback: () => {
          setTrackerType(newVal);
          onChange({ [dataKey]: undefined });
          resetConfirmationModal();
        },
        rightBtnCallback: () => {
          resetConfirmationModal();
        },
      });
    } else {
      setTrackerType(newVal);
      onChange({ [dataKey]: undefined });
    }
  };

  if (!isEditable && _isEmpty(value)) {
    return <FormWrapper {...props}>{fallbackValue}</FormWrapper>;
  }

  const contentTopRender = () => {
    if (enableTrackerType) {
      if (!isEditable) {
        return (
          <div className="tracker-type-wrapper">
            <Text className="mb-2 mt-2" size="small" weight="semiBold" block>
              {trackerTypeProps?.readOnlyInfoText}
            </Text>
            <Text>
              {trackerTypeProps.options.find(o => o.value === trackerType)?.label}
            </Text>
          </div>
        );
      }

      return (
        <div className="tracker-type-wrapper">
          {trackerTypeProps?.infoText ? (
            <Text className="mb-2 mt-2" size="small" weight="semiBold">
              {trackerTypeProps?.infoText}
            </Text>
          ) : null}
          <Dropdown
            className="tracker-type-dropdown"
            type="default"
            placeholder="Select Tracker"
            {...trackerTypeProps}
            onChange={onChangeTrackerType}
            value={trackerType}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div className={`form-component-tracker-container ${!isEditable ? "read-only" : ""}`}>
      <RuleAction
        customAddButton={customAddButton}
        allowSameActions
        actionDropdownProps={{
          customLabelFunction,
        }}
        commonInitValueToAdd={commonInitValueToAdd}
        contentTopRender={contentTopRender}
        {...props}
        actionConfig={actionConfig}
        actionsToShow={actionsToShow}
        setTrackerType={setTrackerType}
      />
    </div>
  );
};

Tracker.defaultProps = {
  fallbackValue: (
    <span className="guide-text">
      <Trans>Not Set</Trans>
    </span>
  ),
  actionsToShow: [],
  actionConfig: {},
  addButtonText: "Add tracker",
};

export default OSHOCWithUtilities(Tracker);
