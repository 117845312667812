import React, { useContext, useState, useMemo, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { useNavigate } from "react-router-dom";
import _uniqBy from "lodash/uniqBy";
import _isEmpty from "lodash/isEmpty";

import { useKeyboard } from "@onlinesales-ai/shortcuts-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { Text } from "@onlinesales-ai/label-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import { setLSItem, getLSItem, isMacOS, pendoTrackEvent } from "@onlinesales-ai/util-methods-v2";

import GlobalSearchContext from "../globalSearchContext";

import "./index.less";

const GlobalSearch = ({ enable, recentSearchLimit, isMobile }) => {
  const { getFilteredList, searchContentList, exploreFeatureList } =
    useContext(GlobalSearchContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [focusIndex, setFocusIndex] = useState(null);
  const [recentSearch, setRecentSearch] = useState([]);
  const resultRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    try {
      const lsData = getLSItem("recentSearch");
      if (lsData) {
        setRecentSearch(JSON.parse(lsData));
      }
    } catch (err) {}
  }, []);

  const storeLocalStorage = (data) => {
    const lsData = JSON.parse(getLSItem("recentSearch"));
    if (lsData?.length === recentSearchLimit) {
      lsData.pop();
    }
    const templObj = _uniqBy([data, ...(lsData || [])]);
    setRecentSearch(templObj);
    setLSItem("recentSearch", JSON.stringify(templObj));
  };

  const recentlySearchedResults = useMemo(() => {
    const results = [];
    recentSearch?.forEach((searchItem) => {
      for (let i = 0; i < searchContentList?.length; i++) {
        if (
          searchContentList[i]?.url === searchItem ||
          searchContentList[i]?.item?.event === searchItem
        ) {
          results.push(searchContentList[i]);
        }
      }
    });
    return results;
  }, [recentSearch, searchContentList]);

  const searchResults = useMemo(() => {
    if (inputValue) {
      return getFilteredList(inputValue);
    } else if (recentlySearchedResults && _isEmpty(inputValue)) {
      const formattedRecentlySearchedResults = recentlySearchedResults?.map((item) => {
        return {
          item,
        };
      });
      return formattedRecentlySearchedResults;
    }
    return [];
  }, [inputValue, recentlySearchedResults]);

  const onClickItem = ({ data, inNewTab, pendoEventName }) => {
    if (data?.externalUrl) {
      window.open(data?.externalUrl, "_blank");
    } else if (data?.url) {
      storeLocalStorage(data?.url);
      if (inNewTab) {
        window.open(data?.url, "_blank");
      } else {
        navigate(data?.url);
      }
    } else if (data?.event) {
      storeLocalStorage(data?.event);
      PlatformEventManager.emit(data?.event);
    }
    setShowModal(false);
    setInputValue("");
    setFocusIndex(null);
    PlatformEventManager.emit("SEARCH_ITEM_SELECTED");
    pendoTrackEvent(pendoEventName, { url: data?.url, event: data?.event, newTab: inNewTab });
  };

  const onClickOpen = useCallback(
    (event) => {
      if (enable) {
        setShowModal((old) => {
          if (!old) {
            pendoTrackEvent(event ? "APP_SEARCH_OPEN_USING_KEYBOARD" : "APP_SEARCH_OPEN_USING_EVENT");
          }
          return !old;
        });
      }
    },
    [enable],
  );

  useEffect(() => {
    if (!enable && showModal) {
      setShowModal(false);
    }
  }, [enable, showModal]);

  useEffect(() => {
    if (showModal && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
        setInputValue("");
      }, 0);
    }
  }, [showModal]);

  useKeyboard("meta+slash", onClickOpen, { enabled: enable });

  useEffect(() => {
    const open = () => {
      onClickOpen();
    };

    PlatformEventManager.on("OPEN_GLOBAL_SEARCH", open);

    return () => {
      PlatformEventManager.off("OPEN_GLOBAL_SEARCH", open);
    };
  }, [enable]);

  const onClickKeyboard = useCallback(
    (event, hotEvent) => {
      const key = hotEvent?.keys?.[0];

      if (key === "esc") {
        setShowModal(false);
        setInputValue("");
        pendoTrackEvent("APP_SEARCH_CLOSE_USING_KEYBOARD");
      } else if (key === "up") {
        if (focusIndex === 0) {
          // setFocusIndex(searchResults.length - 1);
        } else {
          setFocusIndex(focusIndex - 1);
          pendoTrackEvent("APP_SEARCH_ITEM_NAVIGATE_UP_USING_KEYBOARD");
        }
      } else if (key === "down") {
        if (focusIndex === searchResults.length - 1) {
          // setFocusIndex(0);
        } else {
          setFocusIndex(focusIndex + 1);
          pendoTrackEvent("APP_SEARCH_ITEM_NAVIGATE_DOWN_USING_KEYBOARD");
        }
      } else if (key === "enter") {
        if (typeof focusIndex === "number") {
          onClickItem({
            data: searchResults[focusIndex]?.item,
            inNewTab: hotEvent?.ctrl,
            pendoEventName: hotEvent?.ctrl
              ? "APP_SEARCH_ITEM_OPEN_USING_KEYBOARD"
              : "APP_SEARCH_ITEM_OPEN_NEW_TAB_USING_KEYBOARD",
          });
        }
      }
    },
    [focusIndex, searchResults],
  );

  useKeyboard("esc, up, down, enter, ctrl+enter", onClickKeyboard, {
    enabled: showModal,
    enableOnFormTags: true,
    keyup: true,
    keydown: false,
  });

  useEffect(() => {
    if (searchResults?.length) {
      setFocusIndex(0);
    } else {
      setFocusIndex(null);
    }
  }, [searchResults]);

  useEffect(() => {
    if (resultRef.current && typeof focusIndex === "number") {
      const elementToScroll = resultRef.current.querySelector(`[data-index="${focusIndex}"]`);
      if (elementToScroll) {
        const elementRect = elementToScroll.getBoundingClientRect();
        const wrapperRect = resultRef.current.getBoundingClientRect();

        if (elementRect.bottom > wrapperRect.bottom) {
          resultRef.current.scroll({
            top: resultRef.current.scrollTop + elementRect.height,
            behavior: "smooth",
          });
        } else if (elementRect.top < wrapperRect.top) {
          resultRef.current.scroll({
            top: resultRef.current.scrollTop - elementRect.height,
            behavior: "smooth",
          });
        }
      }
    }
  }, [focusIndex]);

  return (
    <ModalWindow
      isShow={showModal}
      containerClass="global-search-modal"
      backdropClassName="dark-backdrop"
      onModalDestroy={() => {
        setShowModal(false);
        setInputValue("");
      }}
    >
      <ModalWindow.Body>
        <div className="search-input-wrapper">
          <input
            type="search"
            ref={inputRef}
            className="search-input"
            onChange={(ev) => {
              setInputValue(ev.target.value);
            }}
            placeholder={t("Search...")}
            onKeyDown={(ev) => {
              if (ev.key === "ArrowUp" || ev.key === "ArrowDown") {
                ev.preventDefault();
              }
            }}
          />
          <span className="icon icon-search-2 search-icon" />
        </div>
        {!inputValue ? (
          <div className="search-wrapper">
            {recentlySearchedResults.length > 0 || exploreFeatureList.length ? (
              <>
                {recentlySearchedResults.length > 0 ? (
                  <div className="recent-search-wrapper">
                    <Text type="primary" className="header mb-1" weight="semiBold">
                      Recently Opened
                    </Text>
                    {recentlySearchedResults.map((item, index) => {
                      return (
                        <div
                          className={`item cursor-pointer ${focusIndex === index ? "focus" : ""}`}
                          onClick={(e) => {
                            e.preventDefault();
                            onClickItem({ data: item, pendoEventName: "APP_SEARCH_ITEM_OPEN_RECENT_SEARCH" });
                          }}
                        >
                          <Text type="secondary" className="recent-title" block>
                            {item.title}
                          </Text>
                          <Text type="tertiary" className="recent-sub-title" size="small">{item.subTitle}</Text>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {exploreFeatureList.length ? (
                  <div className="explore-features">
                    <Text weight="semiBold" className="header mb-3" type="primary">
                      Explore Features
                    </Text>
                    <div className="inner">
                      {exploreFeatureList.map((item) => {
                        return (
                          <div
                            className="item d-align-center cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              onClickItem({ data: item, pendoEventName: "APP_SEARCH_ITEM_OPEN_EXPLORE_FEATURE" });
                            }}
                          >
                            <div className="d-align-center">
                              {item?.image ? <AsyncImage imgSrc={item?.image} imgClassName="expore-img" /> : null}
                              <div className={`d-flex-column ${item?.image ? "ml-4" : ""}`}>
                                <Text weight="semiBold" className="explore-title">
                                  {item.title}
                                </Text>
                                <Text className="explore-sub-title">{item.subTilt}</Text>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="no-options-dom d-center flex-column">
                <div className="no-option-icon d-center">
                  <span className="icon icon-no-results-3" />
                </div>
                <Text>Please type a keyword</Text>
              </div>
            )}
          </div>
        ) : null}
        <div ref={resultRef} className="search-results">
          {inputValue ? (
            <>
              {searchResults?.length !== 0 ? (
                <>
                  {searchResults.map(({ item }, index) => {
                    const { imageUrl, title, subTitle } = item;

                    return (
                      <a
                        data-index={index}
                        className={`search-item ${focusIndex === index ? "focus" : ""}`}
                        onClick={(e) => {
                          e.preventDefault();
                          onClickItem({ data: item, pendoEventName: "APP_SEARCH_ITEM_OPEN_FROM_CLICK" });
                        }}
                      >
                        <div className="d-align-between">
                          <div className="d-align-center">
                            {imageUrl && (
                              <div className="icon-section">
                                <AsyncImage imgSrc={imageUrl} imgClassName="search-image" />
                              </div>
                            )}
                            <div>
                              <Text className="title d-block" type="secondary">{title}</Text>
                              <Text type="tertiary" className="sub-title" size="small">
                                {subTitle}
                              </Text>
                            </div>
                          </div>
                          <div
                            className="open-in-new-tab"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onClickItem({ data: item, inNewTab: true, pendoEventName: "APP_SEARCH_ITEM_OPEN_NEW_TAB_FROM_CLICK" });
                            }}
                          >
                            <span className="icon icon-external-link" />
                          </div>
                        </div>
                      </a>
                    );
                  })}
                </>
              ) : (
                <div className="no-options-dom d-center flex-column">
                  <div className="no-option-icon d-center">
                    <span className="icon icon-no-results-3" />
                  </div>
                  <Text weight="semiBold" className="mb-1">
                    No result found
                  </Text>
                  <Text>Try different keyword</Text>
                </div>
              )}
            </>
          ) : null}
        </div>
        {!isMobile && (
          <div className="outer shortcut-info d-align-end">
            <Text className="text-right d-align-center">
              <Text>Open:</Text>{" "}
              <Text weight="semiBold" className="enter-icon ml-1">
                <span className="icon icon-enter bold-icon" />
              </Text>
            </Text>
            <Text className="text-right d-align-center">
              <Text>Open in New Tab:</Text>
              <Text weight="semiBold" className="ml-1">
                Ctrl +
              </Text>
              <Text weight="semiBold" className="enter-icon ml-1">
                <span className="icon icon-enter bold-icon" />
              </Text>
            </Text>
            <Text className="text-right d-align-center">
              <Text>Select:</Text>
              <Text weight="semiBold" className="select-icon ml-1">
                <span className="icon icon-up-arrow bold-icon" />{" "}
                <span className="icon icon-down-arrow bold-icon" />
              </Text>
            </Text>
            <Text className="text-right">
              <Text>Shortcut:</Text>
              {isMacOS() ? (
                <b className="ml-1">⌘ + /</b>
              ) : (
                <b className="ml-1">
                  <span className="icon icon-windows" /> + /
                </b>
              )}
            </Text>
          </div>
        )}
      </ModalWindow.Body>
    </ModalWindow>
  );
};
const mapStateToProps = (state) => {
  const { isMobile } = state.Application || {};

  return {
    isMobile,
  };
};

GlobalSearch.defaultProps = {
  enable: true,
  recentSearchLimit: 5,
};

export default connect(mapStateToProps)(GlobalSearch);
