import Types from "./types";

const initialState = {
  data: {},
  channelWiseIds: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_AUDIENCE_DATA:
      {
        const oldData = state.data;
        const oldChannelIds = state.channelWiseIds;
        const newActionData = {
          ...oldData,
          ...action.data,
        };
        const newChannelIds = { ...oldChannelIds };

        Object.keys(action.channelWiseIds).forEach((channel) => {
          const oldChannelWiseIdsForChannel = state.channelWiseIds?.[channel] || [];
          const ids = action.channelWiseIds?.[channel];
          const idsToAdd = ids?.filter((id) => !oldChannelWiseIdsForChannel.includes(id));
          newChannelIds[channel] = [
            ...oldChannelWiseIdsForChannel,
            ...idsToAdd,
          ];
        });

        state = {
          ...state,
          data: newActionData,
          channelWiseIds: newChannelIds,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  Audience: reducer,
};
