import React, { useState } from "react";

import { ClientDropdown } from "@onlinesales-ai/client-v2";
import { loaderWhite } from "@onlinesales-ai/loader-v2";

import MediaLibrary from "../mediaLibrary";

const CreativeLibraryWithClientSelection = ({
  mediaLibraryProps,
  mediaUploadProps,
  title,
  ...props
}) => {
  const [selectedEntity, setEntity] = useState({});

  const [fetchState, setFetchState] = useState({ isFetchInProgress: false, fetchErrorMsg: null });

  return (
    <div className="h100p">
      {fetchState.isFetchInProgress ? <>{loaderWhite()}</> : null}
      <div className={`${fetchState.isFetchInProgress ? "d-none" : ""} d-flex-column h100p`}>
        <div className="custom-form-background with-light-bg mr20 pb-3 border-0 mb-3">
          <ClientDropdown
            value={selectedEntity.value}
            onChange={(value, original) => {
              setEntity({ value, entity: original.data });
            }}
            fetchState={fetchState}
            setFetchState={setFetchState}
            // size="extra-large"
            showfullBorder
            containerClass="white-active-bg"
          />
        </div>
        <MediaLibrary
          mediaToShow={["CREATIVES"]}
          clientId={selectedEntity?.entity?.entityId}
          uploadFile={mediaUploadProps}
          height={mediaUploadProps?.dimensionValidation?.height}
          width={mediaUploadProps?.dimensionValidation?.width}
          uploadTags={[...(mediaUploadProps?.uploadTags || []), `creative_user_upload`]}
          containerClass="media-library-creative"
          {...mediaLibraryProps}
          {...props}
        />
      </div>
    </div>
  );
};

export default CreativeLibraryWithClientSelection;
