import Types from "./types";

const initialState = {
  appStoreData: {},
  isAppsFetchInProgress: true,
  appsFetchError: null,
  promotionalData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_APP_STORE_DATA:
      {
        state = {
          ...state,
          appStoreData: action?.appStoreData,
        };
      }
      break;
    case Types.SET_APP_STORE_FETCH_STATE:
      {
        state = {
          ...state,
          isAppsFetchInProgress: action?.isFetchInProgress,
          appsFetchError: action?.error,
        };
      }
      break;
    case Types.SET_APP_PROMOTIONAL_DATA:
      {
        state = {
          ...state,
          promotionalData: action?.promotionalData,
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  AppStore: reducer,
};
