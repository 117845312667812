import domainConfigReducer from "@onlinesales-ai/app-v2/domainConfig/reducer";

const initialState = {};

const reducer = (state = initialState, action) => {
  return domainConfigReducer(state, action);
};

export default {
  DomainConfig: reducer,
};
