import Types from "./types";

const initialState = {
  goalDetails: {},
  isFetchInProgress: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_FETCH_MASTER_GOAL_DATA:
      {
        state = {
          ...state,
          isFetchInProgress: action.isLoading,
        };
      }
      break;
    case Types.SET_MASTER_GOAL_DATA:
      {
        state = {
          ...state,
          goalDetails: action.dataList.reduce(
            (memo, d) => ({
              ...memo,
              [`${d.id}`]: {
                isFetchInProgress: false,
                data: d,
              },
            }),
            {},
          ),
        };
      }
      break;
    case Types.UPDATE_MASTER_GOAL_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [`${action.data.id}`]: {
              isFetchInProgress: false,
              data: {
                ...(state.goalDetails?.[`${action.data.id}`]?.data || {}),
                ...action.data,
              },
            },
          },
        };
      }
      break;
    default:
      break;
  }

  return state;
};

export default {
  MasterGoal: reducer,
};
