import React, { lazy } from "react";
import { ContextBasedDrawer } from "@onlinesales-ai/drawer-v2";

import { drawerProps } from "./index";

const DrawerChildren = lazy(() => import("src/pages/routeBasedDrawer/drawerChildren"));

const ContextBaseDrawerWrapper = () => {
  return (
    <ContextBasedDrawer
      DrawerChildren={DrawerChildren}
      drawerPropsByComponent={drawerProps}
    />
  );
};

export default ContextBaseDrawerWrapper;
